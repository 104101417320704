import { Button } from "@mui/material";
import { useState } from "react";
import { services } from "../../types/services";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { UUID } from "../../types/uuid";
import { showClearPushButton } from "../component-utils";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

type Props = {
  playerStats: Map<string, PlayerStatsWrapper>;
  matchId: UUID;
  onProceed: (updatedStats: Map<string, PlayerStatsWrapper>) => void;
};

export default function ClearBatsmanPushDisplay({
  playerStats,
  matchId,
  onProceed,
}: Readonly<Props>): React.JSX.Element {
  const [showClearPushModal, setShowClearPushModal] = useState<boolean>(false);

  const handleProceed = () => {
    const updatedStats =
      PlayerStatsWrapper.clonePlayerStatsWrapperMap(playerStats);

    updatedStats.forEach((playerStatsWrapper) => {
      playerStatsWrapper.playerStats.pushAdjustment = 0;
      playerStatsWrapper.playerStats.pushAdjustmentUpToBall = 0;
      playerStatsWrapper.playerStats.powerplayPushAdjustment = 0;
    });

    services.playerStatsService.updateMultiplePlayerStats(
      updatedStats,
      matchId
    );

    setShowClearPushModal(false);
    onProceed(updatedStats);
  };

  return (
    <>
      {showClearPushButton(playerStats) && (
        <Button onClick={() => setShowClearPushModal(true)} variant="contained">
          Clear All Batsman Push Adjustments
        </Button>
      )}
      <CreationDialog
        open={showClearPushModal}
        label={"Clear All Batsman Push Adjustments"}
        invalid={false}
        disabled={false}
        onCancel={() => setShowClearPushModal(false)}
        onProceed={handleProceed}
        proceedText="YES"
        cancelText="NO"
      >
        <p style={{ textAlign: "center" }}>Are you sure you want to do this?</p>
      </CreationDialog>
    </>
  );
}
