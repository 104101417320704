import { Match } from "../entities/match";
import { MatchType } from "../enums/match-type";
import { SinceDate } from "../enums/since-date";
import {
  clonePercentDistributionBiasData,
  PercentDistributionBiasData,
} from "../stats/ground-stats";
import { UUID } from "../uuid";

export interface PowerplayAdjustments {
  swingUpToBall: number;
  swingPower: number;
  swingExponent: number;
  srMultiplier: number;
  wicketMultiplier: number;
  basePushAdjustment: number;
  baseSrBias: number;
  baseWicketBias: number;
  powerplayPercentBiases: PercentDistributionBiasData;
}

export type PowerplayAdjustmentField =
  | "swingUpToBall"
  | "swingPower"
  | "swingExponent"
  | "srMultiplier"
  | "wicketMultiplier"
  | "basePushAdjustment"
  | "baseSrBias"
  | "baseWicketBias";

export interface ConfidenceLimits {
  battingBlockingConfidenceLimit: number;
  battingDefendingConfidenceLimit: number;
  battingNormalConfidenceLimit: number;
  battingPushingConfidenceLimit: number;
  battingFullPushConfidenceLimit: number;
  battingGlobalConfidenceLimit: number;
  battingBallsFacedPhase1ConfidenceLimit: number;
  battingBallsFacedPhase2ConfidenceLimit: number;
  battingBallsFacedPhase3ConfidenceLimit: number;
  battingBowlTypeConfidenceLimit: number;
  bowlingBlockingConfidenceLimit: number;
  bowlingDefendingConfidenceLimit: number;
  bowlingNormalConfidenceLimit: number;
  bowlingPushingConfidenceLimit: number;
  bowlingFullPushConfidenceLimit: number;
  bowlingGlobalConfidenceLimit: number;
  bowlingBatTypeConfidenceLimit: number;
  groundBlockingConfidenceLimit: number;
  groundDefendingConfidenceLimit: number;
  groundNormalConfidenceLimit: number;
  groundPushingConfidenceLimit: number;
  groundFullPushConfidenceLimit: number;
  groundGlobalConfidenceLimit: number;
  groundBowlTypeConfidenceLimit: number;
}

export interface AdminConfidences {
  strikeRateConfidenceLimits: ConfidenceLimits;
  wicketPercentConfidenceLimits: ConfidenceLimits;
}

export interface MatchSpecificSettings {
  name: string;
  createdAt: number;
  matchTypes: MatchType[];
  matchFormats: UUID[];
  teams: UUID[];
  series: UUID[];
  twmAtLastBallInnings1: number;
  twmAdditionInnings1: number;
  twmAtLastBallInnings2: number;
  twmAdditionInnings2: number;
  secondInningsPushAdjustment: number;
  secondInningsPushDilution: number;
  playerTwmBallsUntilSet: number;
  playerTwmFirstBallMultiplier: number;
  playerTwmOtherBallMultiplier: number;
  playerTwmDivisor: number;
  adminConfidences: AdminConfidences;
  powerplayAdjustments: PowerplayAdjustments;
  surgeStrikeRateBiasInnings1: number;
  surgeWicketBiasInnings1: number;
  surgeStrikeRateBiasInnings2: number;
  surgeWicketBiasInnings2: number;
  spinMultipliersByPhase: number[][];
  paceMultipliersByPhase: number[][];
}

export type AdminPreferencesField =
  | number
  | string
  | number[][]
  | Toggle[]
  | LeagueStrengthSettings[];

export enum ToggleType {
  SPLIT_POWERPLAY_AND_NON_POWERPLAY = "SPLIT_POWERPLAY_AND_NON_POWERPLAY",
  LEAGUE_STRENGTH = "LEAGUE_STRENGTH",
}

export type LeagueStrengthSelection = {
  international: boolean;
  internationalOppositions: string[];
  domesticLeagues: string[];
  internationalMatchType: MatchType;
};

export type LeagueStrengthSettings = {
  selection: LeagueStrengthSelection;
  paceStrikeRateBias: number;
  paceWicketBias: number;
  spinStrikeRateBias: number;
  spinWicketBias: number;
};

export type Toggle = {
  toggleType: ToggleType;
  enabled: boolean;
};

export const humanReadableToggleTypes: Record<ToggleType, string> = {
  SPLIT_POWERPLAY_AND_NON_POWERPLAY: "Split Powerplay and Non Powerplay",
  LEAGUE_STRENGTH:
    "Use League Strength Module instead of Historic Player/Ground Stats",
};

export interface AdminPreferences {
  adminPreferencesId: UUID;
  confidenceForAllTimeStats: number;
  confidenceForRollingStats: number;
  effectivePercentForAllTimeStats: number;
  matchSpecificSettings: MatchSpecificSettings[];
  defaultSettings: MatchSpecificSettings;
  matchTypeSkewSmoothness: number;
  matchTypeSkewConfidenceLimit: number;
  sinceDate: SinceDate;
  toggles: Toggle[];
  leagueStrengthSettings: LeagueStrengthSettings[];
}

export function clonePowerplayAdjustments(
  original: PowerplayAdjustments
): PowerplayAdjustments {
  return {
    swingUpToBall: original.swingUpToBall,
    swingPower: original.swingPower,
    swingExponent: original.swingExponent,
    srMultiplier: original.srMultiplier,
    wicketMultiplier: original.wicketMultiplier,
    basePushAdjustment: original.basePushAdjustment,
    baseSrBias: original.baseSrBias,
    baseWicketBias: original.baseWicketBias,
    powerplayPercentBiases: clonePercentDistributionBiasData(
      original.powerplayPercentBiases
    ),
  };
}

export function cloneMatchSpecificSettings(
  settings: MatchSpecificSettings
): MatchSpecificSettings {
  return {
    name: settings.name,
    createdAt: settings.createdAt,
    matchTypes: [...settings.matchTypes],
    matchFormats: [...settings.matchFormats],
    teams: [...settings.teams],
    series: [...settings.series],
    twmAtLastBallInnings1: settings.twmAtLastBallInnings1,
    twmAdditionInnings1: settings.twmAdditionInnings1,
    twmAtLastBallInnings2: settings.twmAtLastBallInnings2,
    twmAdditionInnings2: settings.twmAdditionInnings2,
    secondInningsPushAdjustment: settings.secondInningsPushAdjustment,
    secondInningsPushDilution: settings.secondInningsPushDilution,
    adminConfidences: settings.adminConfidences,
    powerplayAdjustments: clonePowerplayAdjustments(
      settings.powerplayAdjustments
    ),
    surgeStrikeRateBiasInnings1: settings.surgeStrikeRateBiasInnings1,
    surgeWicketBiasInnings1: settings.surgeWicketBiasInnings1,
    surgeStrikeRateBiasInnings2: settings.surgeStrikeRateBiasInnings2,
    surgeWicketBiasInnings2: settings.surgeWicketBiasInnings2,
    spinMultipliersByPhase: cloneArray(settings.spinMultipliersByPhase),
    paceMultipliersByPhase: cloneArray(settings.paceMultipliersByPhase),
    playerTwmBallsUntilSet: settings.playerTwmBallsUntilSet,
    playerTwmFirstBallMultiplier: settings.playerTwmFirstBallMultiplier,
    playerTwmOtherBallMultiplier: settings.playerTwmOtherBallMultiplier,
    playerTwmDivisor: settings.playerTwmDivisor,
  };
}

export function isToggleEnabled(
  adminPreferences: AdminPreferences,
  toggleType: ToggleType
): boolean {
  return adminPreferences?.toggles.find(
    (toggle) => toggle.toggleType === toggleType
  )?.enabled;
}

export function deserializeAdminPreferences(json: any): AdminPreferences {
  return {
    adminPreferencesId: !!json.adminPreferencesId
      ? UUID.fromString(json.adminPreferencesId)
      : null,
    confidenceForAllTimeStats: json.confidenceForAllTimeStats,
    confidenceForRollingStats: json.confidenceForRollingStats,
    effectivePercentForAllTimeStats: json.effectivePercentForAllTimeStats,
    matchSpecificSettings: deserializeMatchSpecificSettingsList(
      json.matchSpecificSettings
    ),
    defaultSettings: deserializeMatchSpecificSettings(json.defaultSettings),
    matchTypeSkewSmoothness: json.matchTypeSkewSmoothness,
    matchTypeSkewConfidenceLimit: json.matchTypeSkewConfidenceLimit,
    sinceDate: json.sinceDate,
    toggles: json.toggles,
    leagueStrengthSettings: deserializeLeagueStrengthSettings(
      json.leagueStrengthSettings
    ),
  };
}

export function serializeAdminPreferences(
  adminPreferences: AdminPreferences
): any {
  return {
    adminPreferencesId: !!adminPreferences.adminPreferencesId
      ? adminPreferences.adminPreferencesId.value
      : null,
    confidenceForAllTimeStats: adminPreferences.confidenceForAllTimeStats,
    confidenceForRollingStats: adminPreferences.confidenceForRollingStats,
    effectivePercentForAllTimeStats:
      adminPreferences.effectivePercentForAllTimeStats,
    matchSpecificSettings: serializeMatchSpecificSettingsList(
      adminPreferences.matchSpecificSettings
    ),
    defaultSettings: serializeMatchSpecificSettings(
      adminPreferences.defaultSettings
    ),
    matchTypeSkewSmoothness: adminPreferences.matchTypeSkewSmoothness,
    matchTypeSkewConfidenceLimit: adminPreferences.matchTypeSkewConfidenceLimit,
    sinceDate: adminPreferences.sinceDate,
    toggles: adminPreferences.toggles,
    leagueStrengthSettings: adminPreferences.leagueStrengthSettings,
  };
}

function deserializeLeagueStrengthSettings(
  json: any[]
): LeagueStrengthSettings[] {
  const result: LeagueStrengthSettings[] = [];

  json.forEach((element) => {
    result.push({
      selection: {
        international: element.selection.international,
        internationalMatchType:
          MatchType[element.selection.internationalMatchType],
        internationalOppositions: element.selection.internationalOppositions,
        domesticLeagues: element.selection.domesticLeagues,
      },
      paceStrikeRateBias: element.paceStrikeRateBias,
      paceWicketBias: element.paceWicketBias,
      spinStrikeRateBias: element.spinStrikeRateBias,
      spinWicketBias: element.spinWicketBias,
    });
  });

  return result;
}

function cloneArray(arr: number[][]): number[][] {
  const len = arr.length;
  const copy = new Array(len);
  for (let i = 0; i < len; ++i) {
    copy[i] = arr[i].slice();
  }
  return copy;
}

function deserializeMatchSpecificSettings(entry: any): MatchSpecificSettings {
  const matchTypes: MatchType[] = [];
  const matchFormats: UUID[] = [];
  const teams: UUID[] = [];
  const series: UUID[] = [];

  (entry.matchTypes as any[]).forEach((value) => {
    matchTypes.push(MatchType[value]);
  });

  (entry.matchFormats as any[]).forEach((value) => {
    matchFormats.push(UUID.fromString(value));
  });

  (entry.teams as any[]).forEach((value) => {
    teams.push(UUID.fromString(value));
  });

  (entry.series as any[]).forEach((value) => {
    series.push(UUID.fromString(value));
  });

  return {
    name: entry.name,
    createdAt: entry.createdAt,
    matchTypes,
    matchFormats,
    teams,
    series,
    twmAtLastBallInnings1: entry.twmAtLastBallInnings1,
    twmAdditionInnings1: entry.twmAdditionInnings1,
    twmAtLastBallInnings2: entry.twmAtLastBallInnings2,
    twmAdditionInnings2: entry.twmAdditionInnings2,
    secondInningsPushAdjustment: entry.secondInningsPushAdjustment,
    secondInningsPushDilution: entry.secondInningsPushDilution,
    adminConfidences: entry.adminConfidences,
    powerplayAdjustments: entry.powerplayAdjustments,
    surgeStrikeRateBiasInnings1: entry.surgeStrikeRateBiasInnings1,
    surgeWicketBiasInnings1: entry.surgeWicketBiasInnings1,
    surgeStrikeRateBiasInnings2: entry.surgeStrikeRateBiasInnings2,
    surgeWicketBiasInnings2: entry.surgeWicketBiasInnings2,
    spinMultipliersByPhase: entry.spinMultipliersByPhase,
    paceMultipliersByPhase: entry.paceMultipliersByPhase,
    playerTwmBallsUntilSet: entry.playerTwmBallsUntilSet,
    playerTwmFirstBallMultiplier: entry.playerTwmFirstBallMultiplier,
    playerTwmOtherBallMultiplier: entry.playerTwmOtherBallMultiplier,
    playerTwmDivisor: entry.playerTwmDivisor,
  };
}

function deserializeMatchSpecificSettingsList(
  matchSpecificSettings: any[]
): MatchSpecificSettings[] {
  const list: MatchSpecificSettings[] = [];

  matchSpecificSettings.forEach((entry: any) =>
    list.push(deserializeMatchSpecificSettings(entry))
  );

  return list;
}

function serializeMatchSpecificSettings(entry: MatchSpecificSettings): any {
  const matchTypes: string[] = [];
  const matchFormats: string[] = [];
  const teams: string[] = [];
  const series: string[] = [];

  entry.matchTypes.forEach((value) => {
    matchTypes.push(value);
  });

  entry.matchFormats.forEach((id) => {
    matchFormats.push(id.value);
  });

  entry.teams.forEach((id) => {
    teams.push(id.value);
  });

  entry.series.forEach((id) => {
    series.push(id.value);
  });

  return {
    name: entry.name,
    createdAt: entry.createdAt,
    matchTypes,
    matchFormats,
    teams,
    series,
    twmAtLastBallInnings1: entry.twmAtLastBallInnings1,
    twmAdditionInnings1: entry.twmAdditionInnings1,
    twmAtLastBallInnings2: entry.twmAtLastBallInnings2,
    twmAdditionInnings2: entry.twmAdditionInnings2,
    secondInningsPushAdjustment: entry.secondInningsPushAdjustment,
    secondInningsPushDilution: entry.secondInningsPushDilution,
    adminConfidences: entry.adminConfidences,
    powerplayAdjustments: entry.powerplayAdjustments,
    surgeStrikeRateBiasInnings1: entry.surgeStrikeRateBiasInnings1,
    surgeWicketBiasInnings1: entry.surgeWicketBiasInnings1,
    surgeStrikeRateBiasInnings2: entry.surgeStrikeRateBiasInnings2,
    surgeWicketBiasInnings2: entry.surgeWicketBiasInnings2,
    spinMultipliersByPhase: entry.spinMultipliersByPhase,
    paceMultipliersByPhase: entry.paceMultipliersByPhase,
    playerTwmBallsUntilSet: entry.playerTwmBallsUntilSet,
    playerTwmFirstBallMultiplier: entry.playerTwmFirstBallMultiplier,
    playerTwmOtherBallMultiplier: entry.playerTwmOtherBallMultiplier,
    playerTwmDivisor: entry.playerTwmDivisor,
  };
}

function serializeMatchSpecificSettingsList(
  matchSpecificSettings: MatchSpecificSettings[]
): any[] {
  const list: any[] = [];

  matchSpecificSettings.forEach((entry: MatchSpecificSettings) =>
    list.push(serializeMatchSpecificSettings(entry))
  );

  return list;
}

export function getFavouredMatchSpecificSettings(
  adminPreferences: AdminPreferences,
  match: Match
): MatchSpecificSettings {
  if (!adminPreferences || !match) {
    return null;
  }

  if (
    !adminPreferences.matchSpecificSettings ||
    adminPreferences.matchSpecificSettings.length === 0
  ) {
    return adminPreferences.defaultSettings;
  }

  let score = 0;
  let favouredMatchSpecificSettings: MatchSpecificSettings = null;

  adminPreferences.matchSpecificSettings.forEach(
    (settings: MatchSpecificSettings, i: number) => {
      const scoreTemp = getScore(settings, match);

      if (scoreTemp > score) {
        score = scoreTemp;
        favouredMatchSpecificSettings = settings;
      }
    }
  );

  return favouredMatchSpecificSettings;
}

export function getScore(
  matchSpecificSettings: MatchSpecificSettings,
  match: Match
): number {
  let score = 0;

  if (matchSpecificSettings.matchTypes.includes(match.matchType)) {
    score++;
  } else if (matchSpecificSettings.matchTypes.length > 0) {
    return 0;
  }

  if (matchSpecificSettings.matchFormats.includes(match.matchFormatId)) {
    score++;
  } else if (matchSpecificSettings.matchFormats.length > 0) {
    return 0;
  }

  if (matchSpecificSettings.series.includes(match.seriesId)) {
    score++;
  } else if (matchSpecificSettings.series.length > 0) {
    return 0;
  }

  if (matchSpecificSettings.teams.length > 0) {
    return 0;
  }

  return score;
}
