import { Component, createRef, ReactNode } from "react";
import { Subscription } from "rxjs";
import {
  asBall,
  asBetweenBallsEvent,
  CricketEvent,
  getOver,
} from "../../../types/entities/cricket-event";
import { GameState } from "../../../types/entities/game-state";
import { Match } from "../../../types/entities/match";
import { MatchFormat } from "../../../types/entities/match-format";
import { MatchInfo } from "../../../types/entities/match-info";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { EventType } from "../../../types/enums/event-type";
import { services } from "../../../types/services";
import ScorecardMinimalDisplay from "../scorecard/scorecard-minimal-display";
import { BallDisplay } from "./ball-display";
import BetweenBallsEventDisplay from "./between-balls-event-display";
import { EndOfOverDisplay } from "./end-of-over-display";

interface Props {
  showMinimalScorecard: boolean;
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  match: Match;
  matchInfo: MatchInfo;
}

interface State {
  latestEvents: CricketEvent[];
}

export class EventDisplay extends Component<Props, State> {
  private static readonly NUMBER_TO_SHOW: number = 30;
  static defaultProps = {
    showMinimalScorecard: false,
  };
  private subscriptions: Subscription[];
  private wrapper;

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      latestEvents: [],
    };
    this.wrapper = createRef();
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.currentGameService.latestEventsSubject.subscribe(
        (latestEvents: CricketEvent[]) => {
          this.setState({ latestEvents }, () => {
            this.wrapper.current.scrollLeft = this.wrapper.current.scrollWidth;
            this.setState({});
          });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private drawEvents(): ReactNode {
    const events: ReactNode[] = [];
    let overToggle: boolean = false;
    let inningsNumber: number = -1;
    let overNumber: number = -1;

    this.state.latestEvents
      .slice(
        Math.max(
          0,
          this.state.latestEvents.length - EventDisplay.NUMBER_TO_SHOW
        ),
        this.state.latestEvents.length
      )
      .forEach((event) => {
        const over = getOver(event);
        const innings = event.innings;

        if (over !== overNumber || innings !== inningsNumber) {
          overToggle = !overToggle;
          if (over === 0) {
            events.push(
              <EndOfOverDisplay
                key={"endOfInnings-" + event.eventId.value}
                endOfInnings={true}
                event={event}
                overSummary={
                  !!this.props.gameState &&
                  this.props.gameState.getOverSummary(
                    innings,
                    over,
                    this.props.matchFormat
                  )
                }
              />
            );
          }
          events.push(
            <EndOfOverDisplay
              key={"endOfOver-" + event.eventId.value}
              endOfInnings={false}
              event={event}
              overSummary={
                !!this.props.gameState &&
                this.props.gameState.getOverSummary(
                  innings,
                  over,
                  this.props.matchFormat
                )
              }
            />
          );
        }

        if (event.eventType === EventType.BOWL) {
          const ball = asBall(event);
          events.push(
            <div key={event.eventId.value} className="event">
              <BallDisplay
                key={"event-" + event.eventId.value}
                ball={ball}
                overToggle={overToggle}
                overSummary={
                  !!this.props.gameState &&
                  this.props.gameState.getOverSummary(
                    innings,
                    over,
                    this.props.matchFormat
                  )
                }
                squad1={this.props.squad1}
                squad2={this.props.squad2}
                matchFormat={this.props.matchFormat}
              />
            </div>
          );
        } else {
          events.push(
            <div key={event.eventId.value} className="event">
              <BetweenBallsEventDisplay
                key={"event-" + event.eventId.value}
                event={asBetweenBallsEvent(event)}
                overToggle={overToggle}
                gameState={this.props.gameState}
                squad1={this.props.squad1}
                squad2={this.props.squad2}
                matchFormat={this.props.matchFormat}
                team1={this.props.team1}
                team2={this.props.team2}
              />
            </div>
          );
        }

        overNumber = over;
        inningsNumber = innings;
      });

    return events;
  }

  public render() {
    return (
      <>
        {this.props.showMinimalScorecard && this.props.gameState && (
          <ScorecardMinimalDisplay
            gameState={this.props.gameState}
            squad1={this.props.squad1}
            squad2={this.props.squad2}
            matchFormat={this.props.matchFormat}
            team1={this.props.team1}
            team2={this.props.team2}
            match={this.props.match}
            matchInfo={this.props.matchInfo}
          />
        )}
        <div className="event-list" ref={this.wrapper}>
          {this.drawEvents()}
        </div>
      </>
    );
  }
}
