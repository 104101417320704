import { Box, Tab, Tabs } from "@mui/material";
import { Component, ReactNode } from "react";

import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { MatchStatsWrapper } from "../../types/stats/match-stats";

import { TabPanel } from "../match-page/scorecard/tab-panel";
import { BowlerReservationInningsComponent } from "./bowler-reservation-innings-component";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  matchFormat: MatchFormat;
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  onUpdate: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
}

interface State {
  tabNumber: number;
}

export class BowlerReservationByOverComponent extends Component<Props, State> {
  private static readonly DEFAULT_STATE = {
    tabNumber: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...BowlerReservationByOverComponent.DEFAULT_STATE,
    };
  }

  private propsLoaded(): boolean {
    return (
      !!this.props.matchFormat &&
      !!this.props.currentStats &&
      !!this.props.gameState &&
      !!this.props.squad1 &&
      !!this.props.squad2
    );
  }

  public render() {
    const tabs: ReactNode[] = [];
    const tabPanels: ReactNode[] = [];
    let tabNumber = -1;

    this.propsLoaded() &&
      this.props.matchFormat.inningsConfiguration.forEach(
        (inningsConfiguration, index) => {
          const innings = index + 1;
          if (innings >= this.props.gameState.innings) {
            tabNumber++;
            const bowlingTeamNumber =
              this.props.gameState.getBowlingTeamForInnings(
                innings,
                this.props.matchFormat
              );
            tabs.push(
              <Tab
                label={`Innings ${innings}`}
                key={`bowler-reservation-tab-innings-${innings}`}
                aria-controls={`bowler-reservation-tab-${tabNumber}`}
              />
            );
            tabPanels.push(
              <TabPanel
                value={this.state.tabNumber}
                index={tabNumber}
                key={`bowler-reservation-tab-panel-innings-${innings}`}
              >
                <BowlerReservationInningsComponent
                  originalStats={this.props.currentStats}
                  matchFormat={this.props.matchFormat}
                  gameState={this.props.gameState}
                  bowlingTeam={
                    bowlingTeamNumber === 1
                      ? this.props.squad1.players
                      : this.props.squad2.players
                  }
                  teamId={
                    bowlingTeamNumber === 1
                      ? this.props.squad1.teamId
                      : this.props.squad2.teamId
                  }
                  innings={innings}
                  onUpdate={(currentStats, valid) =>
                    this.props.onUpdate(currentStats, valid)
                  }
                />
                {!!this.props.comparedStats && (
                  <div className="comparison">
                    <div className="comparison-title">
                      Compared to {this.props.comparedUserName}
                    </div>
                    <BowlerReservationInningsComponent
                      originalStats={this.props.comparedStats}
                      matchFormat={this.props.matchFormat}
                      gameState={this.props.gameState}
                      bowlingTeam={
                        bowlingTeamNumber === 1
                          ? this.props.squad1.players
                          : this.props.squad2.players
                      }
                      teamId={
                        bowlingTeamNumber === 1
                          ? this.props.squad1.teamId
                          : this.props.squad2.teamId
                      }
                      innings={innings}
                      onUpdate={(currentStats, valid) =>
                        this.props.onUpdate(currentStats, valid)
                      }
                      disabled
                    />
                  </div>
                )}
              </TabPanel>
            );
          }
        }
      );

    return (
      <div>
        {this.propsLoaded() && (
          <div className="stats-modal-section">
            <div className="stats-modal-section-head">
              Bowler Reservations By Over
            </div>

            <Box className="scorecard-top-buttons">
              <Tabs
                value={this.state.tabNumber}
                onChange={(value, newValue) =>
                  this.setState({ tabNumber: newValue })
                }
                aria-label="Scorecard Tabs"
              >
                {tabs}
              </Tabs>
            </Box>

            {tabPanels}
          </div>
        )}
      </div>
    );
  }
}
