import { ReplaySubject, Subject } from "rxjs";
import { MatchShare } from "../../components/my-matches/match-creation-modals/match-share-modal";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { MatchInfo } from "../../types/entities/match-info";
import { PotentialSharedMatch } from "../../types/entities/potential-shared-match";
import { Series } from "../../types/entities/series";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { Gender } from "../../types/enums/gender";
import { services, showMessage } from "../../types/services";
import { UUID, deserializeUUIDArray } from "../../types/uuid";
import { CricInfoMatch } from "../scraper-service";
import { EntityService } from "./entity-service";

interface CreateMatchRequest {
  team1Id: string;
  team2Id: string;
  seriesId: string;
  groundId: string;
  matchFormatId: string;
  squad1: string[];
  squad2: string[];
  womens: boolean;
}

export class MatchService extends EntityService<Match> {
  private isCreating: boolean = false;
  private readonly matchIdSubject: Subject<UUID> = new ReplaySubject(1);
  public readonly potentialSharedMatchesSubject: Subject<
    PotentialSharedMatch[]
  > = new ReplaySubject(1);
  public readonly sharedUsersSubject: Subject<UUID[]> = new ReplaySubject(1);
  public readonly scrapedMatchSubject: Subject<Match> = new ReplaySubject(1);
  private myMatches: MatchInfo[];
  private readonly myMatchesSubject: Subject<MatchInfo[]> = new ReplaySubject(
    1
  );
  private readonly allMatchesSubject: Subject<MatchInfo[]> = new ReplaySubject(
    1
  );
  public readonly matchesForSeriesSubject: Subject<MatchInfo[]> =
    new ReplaySubject(1);
  public readonly pageCountSubject: Subject<number> = new ReplaySubject(1);

  public readonly MAX_MATCHES: number = 8;

  public getMatchIdSubject(): Subject<UUID> {
    return this.matchIdSubject;
  }

  public getMyMatchesSubject(): Subject<MatchInfo[]> {
    return this.myMatchesSubject;
  }

  public getAllMatchesSubject(): Subject<MatchInfo[]> {
    return this.allMatchesSubject;
  }

  /**
   * updates interface before and after creating match to
   * stop duplicate match creation if double click.
   */
  private setCreating(value: boolean) {
    this.isCreating = value;
  }

  public getIsCreating(): boolean {
    return this.isCreating;
  }

  public async createMatch(
    team1: Team,
    team2: Team,
    series: Series,
    ground: Ground,
    matchFormat: MatchFormat,
    squad1: Squad,
    squad2: Squad
  ): Promise<UUID> {
    this.setCreating(true);
    const createMatchRequest: CreateMatchRequest = {
      team1Id: team1.teamId.value,
      team2Id: team2.teamId.value,
      seriesId: series.seriesId.value,
      groundId: ground.groundId.value,
      matchFormatId: matchFormat.matchFormatId.value,
      squad1: squad1.players.map((p) => p.playerId.value),
      squad2: squad2.players.map((p) => p.playerId.value),
      womens:
        !!squad1.players.find((p) => p.gender === Gender.F) ||
        !!squad2.players.find((p) => p.gender === Gender.F),
    };

    return await this.httpService
      .post("/api/match-controller/create", createMatchRequest)
      .then((response: any) => {
        if (!!response.message) {
          this.setCreating(false);
          showMessage(`Failed to create match: ${response.message}`, "error");
          throw new Error(response.message);
        } else {
          this.setCreating(false);
          const matchId: UUID = UUID.fromString(response);
          this.matchIdSubject.next(matchId);
          return matchId;
        }
      })
      .catch((reason) => {
        this.setCreating(false);
        showMessage(`Failed to create match: ${reason}`, "error");
        throw new Error(reason);
      });
  }

  public async createMatchFromEspnNum(
    cricInfoMatch: CricInfoMatch
  ): Promise<UUID> {
    this.setCreating(true);
    const body = {
      team1Id: cricInfoMatch.team1Id.value,
      team2Id: cricInfoMatch.team2Id.value,
      espnNum: cricInfoMatch.espnNum,
      seriesId: cricInfoMatch.seriesId.value,
      groundId: cricInfoMatch.groundId.value,
      description: cricInfoMatch.description,
      date: cricInfoMatch.date,
      matchGrade: cricInfoMatch.matchGrade,
      status: cricInfoMatch.status,
      statusText: cricInfoMatch.statusText,
      timestamp: cricInfoMatch.timestamp,
    };
    return await this.httpService
      .post("/api/match-controller/create-from-espn", body)
      .then((response: any) => {
        if (!!response.message) {
          this.setCreating(false);
          showMessage(`Failed to create match: ${response.message}`, "error");
          throw new Error(response.message);
        } else {
          this.setCreating(false);
          const matchId: UUID = UUID.fromString(response);
          this.matchIdSubject.next(matchId);
          return matchId;
        }
      })
      .catch((reason) => {
        this.setCreating(false);
        showMessage(`Failed to create match: ${reason}`, "error");
        throw new Error(reason);
      });
  }

  public getScrapedMatch(matchId: UUID) {
    if (!!matchId) {
      this.getOne(matchId).then((match: Match) =>
        this.scrapedMatchSubject.next(match)
      );
    } else {
      this.scrapedMatchSubject.next(null);
    }
  }

  public toggleSims(matchId: UUID) {
    if (!!matchId) {
      const params: Map<string, string> = new Map();
      params.set("matchId", `${matchId.value}`);
      this.httpService
        .get("/api/" + this.controllerName + "/toggle-sims-enabled", params)
        .catch((reason) => {
          showMessage(`Failed to toggle sims for match: ${reason}`, "error");
        });
    }
  }

  public updatePotentialSharedMatches() {
    this.httpService
      .get("/api/" + this.controllerName + "/get-potential-shared-matches")
      .then((response: PotentialSharedMatch[]) => {
        this.potentialSharedMatchesSubject.next(response);
      })
      .catch((reason) => {
        showMessage("Cannot get potential shared matches: " + reason, "error");
      });
  }

  public declinePotentialSharedMatch(potentialSharedMatchId: string) {
    const params: Map<string, string> = new Map();
    params.set("potentialSharedMatchId", potentialSharedMatchId);
    this.httpService
      .get(
        "/api/" + this.controllerName + "/decline-potential-shared-match",
        params
      )
      .catch((reason) => {
        showMessage(
          `Failed to decline potential shared match: ${reason}`,
          "error"
        );
      });
  }

  public acceptPotentialSharedMatch(potentialSharedMatchId: string) {
    const params: Map<string, string> = new Map();
    params.set("potentialSharedMatchId", potentialSharedMatchId);
    this.httpService
      .get(
        "/api/" + this.controllerName + "/accept-potential-shared-match",
        params
      )
      .catch((reason) => {
        showMessage(
          `Failed to accept potential shared match: ${reason}`,
          "error"
        );
      });
  }

  public getMatchesForSeries(series: Series) {
    if (!!series) {
      const params: Map<string, string> = new Map();
      params.set("seriesId", `${series.seriesId.value}`);
      this.httpService
        .get("/api/" + this.controllerName + "/get-for-series", params)
        .then((response) => {
          const matches: MatchInfo[] = MatchInfo.deserializeList(response);
          this.matchesForSeriesSubject.next(matches);
        })
        .catch((reason) => {
          showMessage(`Failed to get matches for series: ${reason}`, "error");
        });
    } else {
      this.matchesForSeriesSubject.next([]);
    }
  }

  public getMyMatches(
    page: number = 0,
    pageSize: number = this.MAX_MATCHES
  ): void {
    const params: Map<string, string> = new Map();
    params.set("page", `${page}`);
    params.set("size", `${pageSize}`);

    this.httpService
      .get("/api/" + this.controllerName + "/all-match-infos", params)
      .then((response) => {
        const { content, totalPages } = response;
        this.myMatches = MatchInfo.deserializeList(content);
        this.myMatchesSubject.next(this.myMatches);
        this.pageCountSubject.next(totalPages);
      })
      .catch((reason) => {
        showMessage(`Failed to get matches: ${reason}`, "error");
      });
  }

  public getAllMatches(page: number = 0, pageSize: number = 999): void {
    const params: Map<string, string> = new Map();
    params.set("page", `${page}`);
    params.set("size", `${pageSize}`);

    this.httpService
      .get("/api/" + this.controllerName + "/all-match-infos", params)
      .then((response) => {
        const { content } = response;
        const matches = MatchInfo.deserializeList(content);
        this.allMatchesSubject.next(matches);
      })
      .catch((reason) => {
        showMessage(`Failed to get all matches: ${reason}`, "error");
      });
  }

  public async delete(matchId: UUID, page: number): Promise<UUID> {
    return await this.httpService
      .post("/api/" + this.controllerName + "/delete", matchId.value)
      .then((deletedMatchId: string) => {
        if (
          services.currentGameService.currentMatchId === null ||
          deletedMatchId === services.currentGameService.currentMatchId.value
        ) {
          services.currentGameService.initMatch();
        }
        this.getMyMatches(page);
        return deletedMatchId;
      })
      .catch((reason) => {
        showMessage("Cannot delete match: " + reason, "error");
        this.getMyMatches(page);
        return null;
      });
  }

  public async share(matchShare: MatchShare) {
    const params: Map<string, string> = new Map();
    params.set("sharedWith", matchShare.userId.value);
    params.set("entityId", matchShare.matchEntityId.value);
    this.httpService
      .get(`/api/` + this.controllerName + `/share`, params, false)
      .then(() => showMessage("Match Shared"))
      .catch((reason) => showMessage(`Failed to share: ${reason}`, "error"));
  }

  public updateShares(matchId: UUID): void {
    this.getShares(matchId).then((shares: UUID[]) =>
      this.sharedUsersSubject.next(shares)
    );
  }

  public async getShares(matchId: UUID): Promise<UUID[]> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return await this.httpService
      .get("/api/" + this.controllerName + "/get-shares", params)
      .then((json: any[]) => {
        const shares: UUID[] = deserializeUUIDArray(json);
        this.handleNoSharesForCurrentMatch(matchId, shares);
        return shares;
      })
      .catch((reason) => {
        showMessage("Cannot get shares: " + reason, "error");
        return null;
      });
  }

  public async removeShare(matchId: UUID, userId: string): Promise<boolean> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    params.set("sharedWith", userId);
    return await this.httpService
      .get("/api/" + this.controllerName + "/remove-share", params)
      .then((b: boolean) => {
        return b;
      })
      .catch((reason) => {
        showMessage("Cannot remove share: " + reason, "error");
        return null;
      });
  }

  private handleNoSharesForCurrentMatch(matchId: UUID, shares: UUID[]) {
    if (
      services.currentGameService.currentMatchId &&
      matchId.value === services.currentGameService.currentMatchId.value &&
      shares.length === 0
    ) {
      services.keycloakService.comparedUserSubject.next(null);
    }
  }

  public async rescrapePlayers(matchId: UUID): Promise<void> {
    return this.httpService
      .post("/api/" + this.controllerName + "/update-squads", matchId.value)
      .then((response: any) => {
        services.currentGameService.matchUpdated(response);
      })
      .catch((reason) => {
        showMessage(`Failed to rescrape players: ${reason}`, "error");
      });
  }
}
