import { Ground } from "../../../types/entities/ground";
import { MatchInfo } from "../../../types/entities/match-info";

interface Props {
  matchInfo: MatchInfo;
  ground: Ground;
}

export default function GameTitle({ matchInfo, ground }: Readonly<Props>) {
  const defaultColour = "#f8f8ff";
  const fallbackColour = "#888888";

  const team1Color = matchInfo?.team1Colour ?? fallbackColour;
  const team2Color = matchInfo?.team2Colour ?? fallbackColour;

  return (
    <div className="match-info-text">
      <div className="team-colour-container">
        <div
          className="team-colour-banner-left"
          style={{ backgroundColor: matchInfo ? team1Color : defaultColour }}
        />
        <div
          className="team-colour-banner-right"
          style={{ backgroundColor: matchInfo ? team2Color : defaultColour }}
        />
      </div>
      {matchInfo === null ? (
        <div className="no-match-selected">No match selected</div>
      ) : (
        <div className="game-title-container">
          <h2 className="versus-text">
            {matchInfo.team1Name}
            <div className="versus-divider">-vs-</div>
            {matchInfo.team2Name}
          </h2>
          <div className="ground-name">at {ground?.name}</div>
        </div>
      )}
    </div>
  );
}
