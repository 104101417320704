import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Badge, Tooltip } from "@mui/material";
import React from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { services } from "../../types/services";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { UUID } from "../../types/uuid";
import { isOverReserved } from "./stats-editing-utils";

type Props = {
  playerId: UUID;
  gameState: GameState;
  matchFormat: MatchFormat;
  matchStats: MatchStatsWrapper;
  teamNumber: number;
};

export default function OversReservationButtons({
  playerId,
  gameState,
  matchFormat,
  matchStats,
  teamNumber,
}: Readonly<Props>): React.JSX.Element {
  const innings = gameState.findFirstBowlingInningsForTeam(
    teamNumber,
    matchFormat
  );
  const teamId = gameState.getTeamId(teamNumber);

  const oversThisInnings = matchFormat?.overConfiguration[innings]?.length + 1; // overs are 0-based in config, 1-based in reservations
  const lastFourOvers = Array.from(
    { length: 4 },
    (_, index) => oversThisInnings - 4 + index
  );

  const onStarClickHandler = (overToReserve: number): void => {
    const newMatchStats = MatchStatsWrapper.clone(matchStats);
    const reservations = newMatchStats.matchStats.overBowlerReservations.get(
      teamId?.value
    );

    if (isOverReserved(playerId, overToReserve, teamId, matchStats)) {
      reservations?.delete(overToReserve);
    } else {
      reservations?.set(overToReserve, playerId.value);
    }

    services.matchStatsService.updateMatchStats(newMatchStats);
  };

  return (
    <div className="player-stats-buttons">
      {lastFourOvers.map((overNumber) => {
        const isReserved = isOverReserved(
          playerId,
          overNumber,
          teamId,
          matchStats
        );

        return (
          <Tooltip
            key={overNumber}
            title={
              isReserved
                ? `Unreserve for over ${overNumber}`
                : `Reserve for over ${overNumber}`
            }
          >
            <Badge
              badgeContent={overNumber}
              color={isReserved ? "primary" : "default"}
              overlap="circular"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              style={{ cursor: "pointer", margin: "5px" }}
              onClick={() => onStarClickHandler(overNumber)}
            >
              {isReserved ? (
                <StarIcon style={{ color: "gold" }} />
              ) : (
                <StarBorderIcon style={{ color: "gray" }} />
              )}
            </Badge>
          </Tooltip>
        );
      })}
    </div>
  );
}
