import { Tooltip } from "@mui/material";
import { BetweenBallsEvent } from "../../../types/entities/between-balls-event";
import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { InningsStatisticType } from "../../../types/enums/statistic-type";
import { getEventIcon, getEventTooltip } from "./between-balls-event-utils";

interface Props {
  event: BetweenBallsEvent;
  overToggle: boolean;
  gameState: GameState;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
}
export default function BetweenBallsEventDisplay({
  event,
  overToggle,
  gameState,
  squad1,
  squad2,
  team1,
  team2,
  matchFormat,
}: Readonly<Props>): React.JSX.Element {
  if (!squad1 || !squad2 || !event) {
    return <div></div>;
  }

  const surgeOver = gameState
    ? gameState.getInningsStatForInnings(
        event.innings,
        InningsStatisticType.SURGE_OVER,
        -1
      )
    : -1;
  const inningsConfiguration =
    !!matchFormat && matchFormat.inningsConfiguration[event.innings - 1];
  const ballInSurge: boolean =
    !!inningsConfiguration &&
    surgeOver !== -1 &&
    Number(event.over) >= surgeOver &&
    Number(event.over) < surgeOver + inningsConfiguration.surgeOvers;
  const overEvenOddClass = overToggle ? " over-even" : " over-odd";
  const surgeOverEvenOddClass = overToggle
    ? " over-even-surge"
    : " over-odd-surge";

  return (
    <Tooltip
      title={getEventTooltip(squad1, squad2, team1, team2, matchFormat, event)}
    >
      <div
        className={
          "between-balls-event-display" +
          (ballInSurge ? surgeOverEvenOddClass : overEvenOddClass)
        }
      >
        {getEventIcon(event)}
      </div>
    </Tooltip>
  );
}
