export enum ShareStatus {
  POTENTIAL = "POTENTIAL",
  ACCEPTED = "ACCEPTED",
}

export interface PotentialSharedMatch {
  createdBy: string;
  sharedWith: string;
  matchId: string;
  potentialSharedMatchId: string;
  description: string;
  shareStatus: ShareStatus;
}
