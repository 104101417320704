import { Icon } from "@mui/material";
import { ReactElement } from "react";
import { ReactComponent as AddPlayerSVG } from "../../../img/events/add-player.svg";
import { ReactComponent as BatsmanSelectedSVG } from "../../../img/events/batsman-selected.svg";
import { ReactComponent as BatsmenSwitchedSVG } from "../../../img/events/batsmen-switched.svg";
import { ReactComponent as BowlerSelectedSVG } from "../../../img/events/bowler-selected.svg";
import { ReactComponent as DeclaredSVG } from "../../../img/events/declared.svg";
import { ReactComponent as IncompleteInningsSVG } from "../../../img/events/innings-incomplete.svg";
import { ReactComponent as MoveDownSVG } from "../../../img/events/move-down.svg";
import { ReactComponent as MoveUpSVG } from "../../../img/events/move-up.svg";
import { ReactComponent as PenaltySVG } from "../../../img/events/penalty.svg";
import { ReactComponent as RainSVG } from "../../../img/events/rain.svg";
import { ReactComponent as RemovePlayerSVG } from "../../../img/events/remove-player.svg";
import { ReactComponent as RescrapePlayersSVG } from "../../../img/events/rescrape-players.svg";
import { ReactComponent as RetiredReturnedSVG } from "../../../img/events/retired-returned.svg";
import { ReactComponent as RetiredBatsmanSVG } from "../../../img/events/retired.svg";
import { ReactComponent as StatsLoadedSVG } from "../../../img/events/stats-loaded.svg";
import { ReactComponent as SubstituteSVG } from "../../../img/events/substitute.svg";
import { ReactComponent as SuperOverSVG } from "../../../img/events/super-over.svg";
import { ReactComponent as SurgeTakenSVG } from "../../../img/events/surge-taken.svg";
import { ReactComponent as TiedSVG } from "../../../img/events/tied.svg";
import { ReactComponent as TimedOutSVG } from "../../../img/events/timed-out.svg";
import { ReactComponent as TossSVG } from "../../../img/events/toss.svg";
import { BetweenBallsEvent } from "../../../types/entities/between-balls-event";
import { MatchFormat } from "../../../types/entities/match-format";
import { Squad } from "../../../types/entities/squad";
import { Team } from "../../../types/entities/team";
import { Direction } from "../../../types/enums/direction";
import { EventType } from "../../../types/enums/event-type";
import { services } from "../../../types/services";
import { UUID } from "../../../types/uuid";

export function getEventTooltip(
  squad1: Squad,
  squad2: Squad,
  team1: Team,
  team2: Team,
  matchFormat: MatchFormat,
  event: BetweenBallsEvent
): string {
  if (!squad1 || !squad2 || !team1 || !team2 || !matchFormat || !event) {
    return "Loading..";
  }

  const battingTeam: Squad =
    matchFormat?.inningsConfiguration[event.innings - 1]?.battingTeam === 1
      ? squad1
      : squad2;
  const bowlingTeam: Squad =
    matchFormat?.inningsConfiguration[event.innings - 1]?.battingTeam === 1
      ? squad2
      : squad1;

  switch (event.eventType) {
    case EventType.RETIRED_BATSMAN_RETURNED:
      return (
        getPlayerName(event.eventData.player, battingTeam) +
        " returned from injury"
      );
    case EventType.RETIRED_BATSMAN:
      return (
        getPlayerName(event.eventData.player, battingTeam) + " retired hurt"
      );
    case EventType.RETIRED_BOWLER_RETURNED:
      return (
        getPlayerName(event.eventData.value, bowlingTeam) +
        " returned from injury"
      );
    case EventType.RETIRED_BOWLER:
      return (
        getPlayerName(event.eventData.player, bowlingTeam) + " retired hurt"
      );
    case EventType.BATSMAN_SELECTED:
      return (
        getPlayerName(event.eventData.player, battingTeam) + " selected to bat"
      );
    case EventType.SUBSTITUTION:
      return getSubstitutionTooltip(event, battingTeam, bowlingTeam);
    case EventType.BOWLER_SELECTED:
      return (
        getPlayerName(event.eventData.value, bowlingTeam) + " selected to bowl"
      );
    case EventType.END_OF_INNINGS_CHOICE:
      return "Innings ended, not enough batsmen to continue";
    case EventType.DECLARATION:
      return "Batting team declared";
    case EventType.BATSMEN_SWITCHED:
      return "Batsmen crossed";
    case EventType.FORMAT_CHANGE:
      return "Format Change";
    case EventType.TIMED_OUT:
      return getPlayerName(event.eventData.player, battingTeam) + " timed out";
    case EventType.PENALTY:
      return (
        event.eventData.value +
        " penalty runs awarded to innings " +
        event.eventData.innings
      );
    case EventType.SUPER_OVER:
      return event.eventData.superOverTaken
        ? "Super over taken"
        : "Match drawn";
    case EventType.MOVE_PLAYER:
      return getMovePlayerTooltip(event, squad1, squad2, team1);
    case EventType.TOSS:
      return getTossTooltip(event, team1, team2);
    case EventType.STATS_LOADED:
      return "Stats loaded";
    case EventType.SURGE_TAKEN:
      return "Surge taken";
    case EventType.ADD_PLAYER:
      return getAddPlayerTooltip(event, squad1, squad2, team1, team2);
    case EventType.REMOVE_PLAYER:
      return getRemovePlayerTooltip(event, squad1, squad2, team1, team2);
    case EventType.RESCRAPE_PLAYERS:
      return "Rescrape Squads";
    default:
      return "Unknown Event";
  }
}

export function getEventIcon(event: BetweenBallsEvent): ReactElement {
  switch (event.eventType) {
    case EventType.RETIRED_BATSMAN_RETURNED:
    case EventType.RETIRED_BOWLER_RETURNED:
      return <RetiredReturnedSVG />;
    case EventType.RETIRED_BATSMAN:
    case EventType.RETIRED_BOWLER:
      return <RetiredBatsmanSVG />;
    case EventType.BATSMAN_SELECTED:
      return <BatsmanSelectedSVG />;
    case EventType.SUBSTITUTION:
      return <SubstituteSVG />;
    case EventType.BOWLER_SELECTED:
      return <BowlerSelectedSVG />;
    case EventType.END_OF_INNINGS_CHOICE:
      return <IncompleteInningsSVG />;
    case EventType.BATSMEN_SWITCHED:
      return <BatsmenSwitchedSVG />;
    case EventType.DECLARATION:
      return <DeclaredSVG />;
    case EventType.TIMED_OUT:
      return <TimedOutSVG />;
    case EventType.PENALTY:
      return <PenaltySVG />;
    case EventType.FORMAT_CHANGE:
      return <RainSVG />;
    case EventType.SUPER_OVER:
      return event.eventData.superOverTaken ? <SuperOverSVG /> : <TiedSVG />;
    case EventType.MOVE_PLAYER:
      return event.eventData.direction === Direction.UP ? (
        <MoveUpSVG />
      ) : (
        <MoveDownSVG />
      );
    case EventType.TOSS:
      return <TossSVG />;
    case EventType.STATS_LOADED:
      return <StatsLoadedSVG />;
    case EventType.SURGE_TAKEN:
      return <SurgeTakenSVG />;
    case EventType.ADD_PLAYER:
      return <AddPlayerSVG />;
    case EventType.RESCRAPE_PLAYERS:
      return <RescrapePlayersSVG />;
    case EventType.REMOVE_PLAYER:
      return <RemovePlayerSVG />;
    default:
      return <Icon>question_mark</Icon>;
  }
}

function getTeamName(teamId: string, team1: Team, team2: Team): string {
  return teamId === team1.teamId.value ? team1.name : team2.name;
}

function getPlayerName(playerIdString: string, squad: Squad): string {
  const playerId =
    playerIdString === null ? null : UUID.fromString(playerIdString);
  return services.teamPlayerService.getPlayerName(playerId, squad);
}

function getSubstitutionTooltip(
  event: BetweenBallsEvent,
  battingTeam: Squad,
  bowlingTeam: Squad
): string {
  const team =
    event.eventData.substitution.teamId === battingTeam.teamId.value
      ? battingTeam
      : bowlingTeam;

  if (event.eventData.substitution.isConcussionSub) {
    return (
      getPlayerName(event.eventData.substitution.playerReplacing, team) +
      " replaced " +
      getPlayerName(event.eventData.substitution.playerReplaced, team) +
      " as a concussion substitute for the " +
      (team === battingTeam ? "batting" : "bowling") +
      " team"
    );
  } else {
    return (
      getPlayerName(event.eventData.substitution.playerReplacing, team) +
      " came on as a substitute for the " +
      (team === battingTeam ? "batting" : "bowling") +
      " team"
    );
  }
}

function getMovePlayerTooltip(
  event: BetweenBallsEvent,
  squad1: Squad,
  squad2: Squad,
  team1: Team
): string {
  const squad =
    team1 && event.eventData.teamId === team1.teamId.value ? squad1 : squad2;
  const name = getPlayerName(event.eventData.playerId, squad);
  const direction = event.eventData.direction === Direction.UP ? "up" : "down";
  return `${name} moved ${direction} the order`;
}

function getAddPlayerTooltip(
  event: BetweenBallsEvent,
  squad1: Squad,
  squad2: Squad,
  team1: Team,
  team2: Team
): string {
  const addedTeamPlayer = event.eventData.teamPlayer;
  const nameInSquad1 = getPlayerName(addedTeamPlayer.playerId, squad1);
  const nameInSquad2 = getPlayerName(addedTeamPlayer.playerId, squad2);
  return (
    (nameInSquad1 === "Unknown" ? nameInSquad2 : nameInSquad1) +
    " added to " +
    getTeamName(addedTeamPlayer.teamId, team1, team2)
  );
}

function getRemovePlayerTooltip(
  event: BetweenBallsEvent,
  squad1: Squad,
  squad2: Squad,
  team1: Team,
  team2: Team
): string {
  const removedFromTeam: UUID = event.eventData.teamId
    ? UUID.fromString(event.eventData.teamId)
    : null;
  const nameInTeam1 = getPlayerName(event.eventData.playerId, squad1);
  const nameInTeam2 = getPlayerName(event.eventData.playerId, squad2);
  return (
    (nameInTeam1 !== "Unknown" ? nameInTeam1 : nameInTeam2) +
    " removed from " +
    (removedFromTeam?.value === team1.teamId.value ? team1.name : team2.name)
  );
}

function getTossTooltip(
  event: BetweenBallsEvent,
  team1: Team,
  team2: Team
): string {
  const teamId = event.eventData.tossWinningTeamId;
  const decision = event.eventData.decision;
  return (
    getTeamName(teamId, team1, team2) +
    " won the toss and elected to " +
    (decision === "BAT" ? "bat" : "bowl")
  );
}
