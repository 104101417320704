import { Line } from "react-chartjs-2";
import { PowerplayAdjustments } from "../../types/preferences/admin-preferences";
import { calculatePowerplayAdjustments } from "./powerplay-adjustments-utils";

interface Props {
  adjustments: PowerplayAdjustments;
  ballsInPowerplay: number;
}

export default function PowerplayAdjustmentsGraph({
  adjustments,
  ballsInPowerplay,
}: Readonly<Props>): React.JSX.Element {
  const xValues = Array.from(
    { length: ballsInPowerplay },
    (_value, index) => index
  );

  const datasets = calculatePowerplayAdjustments(adjustments, ballsInPowerplay);

  const srAndWicketData = {
    labels: xValues,
    datasets: [
      {
        label: "Strike Rate",
        data: datasets[0],
        borderColor: "blue",
        backgroundColor: "rgba(0, 0, 255, 0.1)",
      },
      {
        label: "Wicket Percent",
        data: datasets[1],
        borderColor: "red",
        backgroundColor: "rgba(255, 0, 0, 0.1)",
      },
    ],
  };

  const srAndWicketOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Ball",
        },
      },
      y: {
        title: {
          display: true,
          text: "SR - W%",
        },
      },
    },
    responsive: true,
  };

  const pushData = {
    labels: xValues,
    datasets: [
      {
        label: "Push",
        data: datasets[2],
        borderColor: "green",
        backgroundColor: "rgba(0, 255, 0, 0.1)",
      },
    ],
  };

  const pushOptions = {
    scales: {
      x: {
        title: {
          display: true,
          text: "Ball",
        },
      },
      y: {
        title: {
          display: true,
          text: "Push",
        },
      },
    },
    responsive: true,
  };

  return (
    <div className="match-stats-powerplay-columns">
      <div className="match-stats-powerplay-settings-column">
        <Line data={srAndWicketData} options={srAndWicketOptions} />
      </div>
      <div className="match-stats-powerplay-settings-column">
        <Line data={pushData} options={pushOptions} />
      </div>
    </div>
  );
}
