import { ReplaySubject, Subject } from "rxjs";
import { Match } from "../types/entities/match";
import { services, showMessage } from "../types/services";
import { MatchStats, MatchStatsWrapper } from "../types/stats/match-stats";
import { UUID } from "../types/uuid";
import { EntityService } from "./entity-services/entity-service";
import { KeycloakUser } from "./keycloak-service";

export class MatchStatsService extends EntityService<MatchStatsWrapper> {
  public matchStatsSubject: Subject<MatchStatsWrapper> = new ReplaySubject(1);
  public matchStats: MatchStatsWrapper = null;

  public comparedMatchStatsSubject: Subject<MatchStatsWrapper> =
    new ReplaySubject(1);
  public teamMatchStatsSubject: Subject<Map<string, MatchStatsWrapper>> =
    new ReplaySubject(1);
  public teamMatchStats: Map<string, MatchStatsWrapper> = null;

  public initiateLoadedStats() {
    services.currentGameService.currentMatchSubject.subscribe(
      (currentMatch: Match) => this.updateMatch(currentMatch)
    );
    services.keycloakService.comparedUserSubject.subscribe(
      (comparedUser: KeycloakUser) => this.broadcastComparedStats(comparedUser)
    );
  }

  private broadcastComparedStats(comparedUser: KeycloakUser) {
    this.teamMatchStatsSubject.next(this.teamMatchStats);
    if (!!comparedUser) {
      this.comparedMatchStatsSubject.next(
        this.teamMatchStats.get(comparedUser.id) || null
      );
    } else {
      this.comparedMatchStatsSubject.next(null);
    }
  }

  private updateMatch(match: Match) {
    if (!!match) {
      this.getMatchStats(match.matchId).then(
        (matchStats: MatchStatsWrapper) => {
          if (!!matchStats) {
            this.matchStats = matchStats;
            this.matchStatsSubject.next(matchStats);
          }
        }
      );
      this.getTeamMatchStats(match.matchId).then(
        (teamStats: Map<string, MatchStatsWrapper>) => {
          if (!!teamStats) {
            this.teamMatchStats = teamStats;
            this.broadcastComparedStats(services.keycloakService.comparedUser);
          }
        }
      );
    } else {
      this.matchStats = null;
      this.matchStatsSubject.next(null);
      this.teamMatchStats = new Map();
      this.broadcastComparedStats(services.keycloakService.comparedUser);
    }
  }

  private async getMatchStats(matchId: UUID): Promise<MatchStatsWrapper> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return await this.httpService
      .get(`/api/${this.controllerName}/match-stats`, params)
      .then((response: any) => {
        if (response === true) {
          return null;
        } else {
          return MatchStatsWrapper.deserializeOne(response);
        }
      })
      .catch((reason) => {
        showMessage(`Failed to load match stats: ${reason}`, "error");
        return null;
      });
  }

  private async getTeamMatchStats(
    matchId: UUID
  ): Promise<Map<string, MatchStatsWrapper>> {
    const params: Map<string, string> = new Map();
    params.set("matchId", matchId.value);
    return await this.httpService
      .get(`/api/${this.controllerName}/team-match-stats`, params)
      .then((response: any) => {
        if (response === true) {
          return new Map();
        } else {
          const map: Map<string, MatchStatsWrapper> = new Map();
          Object.keys(response).forEach((userId) => {
            map.set(userId, MatchStatsWrapper.deserializeOne(response[userId]));
          });
          return map;
        }
      })
      .catch((reason) => {
        showMessage(`Failed to load team match stats: ${reason}`, "error");
        return null;
      });
  }

  public async getDefaultMatchStats(
    matchFormatId: UUID,
    matchId: UUID
  ): Promise<MatchStats> {
    const params: Map<string, string> = new Map();
    params.set("matchFormatId", matchFormatId.value);
    params.set("matchId", matchId.value);
    return await this.httpService
      .get(`/api/${this.controllerName}/get-default-match-stats`, params)
      .then((response: any) => {
        return MatchStatsWrapper.deserializeMatchStats(response);
      })
      .catch((reason) => {
        showMessage(`Failed to get default match stats: ${reason}`, "error");
        return null;
      });
  }

  public updateMatchStats(matchStats: MatchStatsWrapper) {
    this.httpService
      .post(
        `/api/${this.controllerName}/update-match-stats`,
        MatchStatsWrapper.serialize(matchStats)
      )
      .then((response) => {
        showMessage("Match stats saved", "success");
      })
      .catch((reason) => {
        showMessage(`Failed to update match stats: ${reason}`, "error");
        return null;
      });
  }

  public webSocketStatsUpdated(message: any) {
    const newMatchStats = !!message.stats
      ? MatchStatsWrapper.deserializeOne(message.stats)
      : null;
    if (
      !!newMatchStats &&
      !!newMatchStats.matchId &&
      !!services.currentGameService.currentMatchId &&
      newMatchStats.matchId.value ===
        services.currentGameService.currentMatchId.value
    ) {
      if (message.causingUser === services.keycloakService.getUserId()) {
        this.matchStats = newMatchStats;
        this.matchStatsSubject.next(newMatchStats);
      } else {
        this.teamMatchStats.set(message.causingUser, newMatchStats);
        this.broadcastComparedStats(services.keycloakService.comparedUser);
      }
    }
  }
}
