import { UUID } from "../uuid";
import { BetType } from "./bet-type";

export interface BetData {
  subType: string;
  fullJson: string;
}

export interface BatsmanBallsFacedBetData extends BetData {
  ballsFaced: number;
  playerId: UUID;
  playerName: string;
}

export interface InningsRunsBetData extends BetData {
  runs: number;
  innings: number;
}

export interface MatchOddsBetData extends BetData {
  teamId: UUID;
  teamName: string;
}

export interface TopBatBetData extends BetData {
  teamId: UUID;
  teamName: string;
  playerId: UUID;
  playerName: string;
}

export interface BatsmanBoundariesBetData extends BetData {
  boundaries: number;
  playerId: UUID;
  playerName: string;
}

export interface SessionBetData extends BetData {
  runs: number;
  overLow: number;
  overHigh: number;
  teamId: UUID;
  teamName: string;
}

export interface BatsmanRunsBetData extends BetData {
  runs: number;
  playerId: UUID;
  playerName: string;
}

export interface MatchRunsBetData extends BetData {
  runs: number;
}

export interface TiedMatchBetData extends BetData {
  tied: boolean;
}

export interface FallOfWicketBetData extends BetData {
  runs: number;
  wickets: number;
  innings: number;
}

export interface CompletedMatchBetData extends BetData {
  completed: boolean;
}

export interface ErrorBetData extends BetData {
  selectionDetails: string;
  fullJson: string;
  originalBetType: BetType;
}

export function deserializeBetData(betType: BetType, json: any): BetData {
  switch (betType) {
    case BetType.MATCH_ODDS:
    case BetType.SUPER_OVER:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        teamId: (json.teamId && UUID.fromString(json.teamId)) || null,
        teamName: json.teamName,
      } as MatchOddsBetData;
    case BetType.FALL_OF_WICKET:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        runs: json.runs,
        wickets: json.wickets,
        innings: json.innings,
      } as FallOfWicketBetData;
    case BetType.SESSION:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        runs: json.runs,
        overLow: json.overLow,
        overHigh: json.overHigh,
        teamId: (json.teamId && UUID.fromString(json.teamId)) || null,
        teamName: json.teamName,
      } as SessionBetData;
    case BetType.INNINGS_RUNS:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        runs: json.runs,
        innings: json.innings,
      } as InningsRunsBetData;
    case BetType.BATSMAN_RUNS:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        runs: json.runs,
        playerId: (json.playerId && UUID.fromString(json.playerId)) || null,
        playerName: json.playerName,
      } as BatsmanRunsBetData;
    case BetType.BATSMAN_BOUNDARIES:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        boundaries: json.boundaries,
        playerId: (json.playerId && UUID.fromString(json.playerId)) || null,
        playerName: json.playerName,
      } as BatsmanBoundariesBetData;
    case BetType.BATSMAN_BALLS_FACED:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        ballsFaced: json.ballsFaced,
        playerId: (json.playerId && UUID.fromString(json.playerId)) || null,
        playerName: json.playerName,
      } as BatsmanBallsFacedBetData;
    case BetType.MATCH_RUNS:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        runs: json.runs,
      } as MatchRunsBetData;
    case BetType.TIED_MATCH:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        tied: json.tied,
      } as TiedMatchBetData;
    case BetType.TOP_BAT:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        teamId: (json.teamId && UUID.fromString(json.teamId)) || null,
        teamName: json.teamName,
        playerId: (json.playerId && UUID.fromString(json.playerId)) || null,
        playerName: json.playerName,
      } as TopBatBetData;
    case BetType.COMPLETED_MATCH:
      return {
        subType: json.subType,
        fullJson: json.fullJson,
        completed: json.completed,
      } as CompletedMatchBetData;
    case BetType.UNKNOWN:
      return {
        subType: json.subType,
        selectionDetails: json.selectionDetails,
        fullJson: json.fullJson,
        originalBetType: BetType[json.originalBetType],
      } as BetData;
  }
}
