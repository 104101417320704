import { Entity } from "../entities/entity";
import { Match } from "../entities/match";
import { MatchFormat } from "../entities/match-format";
import { TrafficLight } from "../enums/traffic-light";
import { PowerplayAdjustments } from "../preferences/admin-preferences";
import { services } from "../services";
import { UUID } from "../uuid";

export interface MatchStats {
  strikeRateBiasByBall: Map<number, number>;
  wicketBiasByBall: Map<number, number>;
  pushByBall: Map<number, number>;
  paceStrikeRateBiasByBall: Map<number, number>;
  paceWicketBiasByBall: Map<number, number>;
  pacePushByBall: Map<number, number>;
  spinStrikeRateBiasByBall: Map<number, number>;
  spinWicketBiasByBall: Map<number, number>;
  spinPushByBall: Map<number, number>;
  overBowlerReservations: Map<string, Map<number, string>>;
  surgeBowlerReservations: Map<string, string[]>;
  groundWeight: number;
  teamWeights: Map<string, number>;
  batsmanMatchStrikeRateAdjustments: Map<string, Map<string, number>>;
  batsmanMatchWicketPercentAdjustments: Map<string, Map<string, number>>;
  bowlerMatchStrikeRateAdjustments: Map<string, Map<string, number>>;
  bowlerMatchWicketPercentAdjustments: Map<string, Map<string, number>>;
  bowlerMatchPushAdjustments: Map<string, Map<string, number>>;
  partnershipPushAdjustments: Map<string, Map<string, number>>;
  overrideAheadAndBehindLimit: boolean;
  aheadLimit: number;
  behindLimit: number;
  spinMultipliers: number[][];
  paceMultipliers: number[][];
  homeAdvantageBattingStrikeRateBias: number;
  homeAdvantageBattingWicketBias: number;
  homeAdvantageBowlingStrikeRateBias: number;
  homeAdvantageBowlingWicketBias: number;
  homeTeamId: UUID;
  twmAtLastBallInnings1Team1: number;
  twmAdditionInnings1Team1: number;
  twmAtLastBallInnings2Team1: number;
  twmAdditionInnings2Team1: number;
  secondInningsPushAdjustmentTeam1: number;
  secondInningsPushDilutionTeam1: number;
  twmAtLastBallInnings1Team2: number;
  twmAdditionInnings1Team2: number;
  twmAtLastBallInnings2Team2: number;
  twmAdditionInnings2Team2: number;
  secondInningsPushAdjustmentTeam2: number;
  secondInningsPushDilutionTeam2: number;
  playerTwmBallsUntilSet: number;
  playerTwmFirstBallMultiplier: number;
  playerTwmOtherBallMultiplier: number;
  playerTwmDivisor: number;
  traderConfidence: TrafficLight;
  powerplayAdjustmentsInnings1: PowerplayAdjustments;
  powerplayAdjustmentsInnings2: PowerplayAdjustments;
}

export interface Partnership {
  batsman1: string;
  batsman2: string;
}

export class MatchStatsWrapper implements Entity {
  public matchStatsId: UUID;
  public matchId: UUID;
  public createdBy: UUID;
  public createdAt: number;
  public matchStats: MatchStats;

  constructor(
    matchStatsId: UUID,
    matchId: UUID,
    createdBy: UUID,
    createdAt: number,
    matchStats: MatchStats
  ) {
    this.matchStatsId = matchStatsId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.matchId = matchId;
    this.matchStats = matchStats;
  }

  getStats(): MatchStats {
    return this.matchStats;
  }

  public getAllProperties(): string[] {
    return Object.keys(this.matchStats);
  }

  newWithUpdatedPoints(
    updatedDataPoints: Map<number, number>,
    selectedGraph: string
  ): MatchStatsWrapper {
    return new MatchStatsWrapper(
      this.matchStatsId,
      this.matchId,
      this.createdBy,
      this.createdAt,
      {
        ...this.matchStats,
        [selectedGraph]: updatedDataPoints,
      }
    );
  }

  public static serialize(matchStatsWrapper: MatchStatsWrapper) {
    return {
      matchStatsId: !!matchStatsWrapper.matchStatsId
        ? matchStatsWrapper.matchStatsId.value
        : null,
      matchId: !!matchStatsWrapper.matchId
        ? matchStatsWrapper.matchId.value
        : null,
      createdBy: !!matchStatsWrapper.createdBy
        ? matchStatsWrapper.createdBy.value
        : null,
      createdAt: matchStatsWrapper.createdAt,
      matchStats: MatchStatsWrapper.serializeMatchStats(
        matchStatsWrapper.matchStats
      ),
    };
  }

  public static deserializeOne(responseJSON: any): MatchStatsWrapper {
    return new MatchStatsWrapper(
      UUID.fromString(responseJSON.matchStatsId),
      UUID.fromString(responseJSON.matchId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      MatchStatsWrapper.deserializeMatchStats(responseJSON.matchStats)
    );
  }

  public static deserializeList(json: any): MatchStatsWrapper[] {
    const stats: MatchStatsWrapper[] = [];
    json.forEach((element) => {
      stats.push(this.deserializeOne(element));
    });
    return stats;
  }

  public static deserializeMatchStats(json: any): MatchStats {
    return {
      strikeRateBiasByBall: MatchStatsWrapper.deserializeMap(
        json.strikeRateBiasByBall
      ),
      wicketBiasByBall: MatchStatsWrapper.deserializeMap(json.wicketBiasByBall),
      pushByBall: MatchStatsWrapper.deserializeMap(json.pushByBall),
      paceStrikeRateBiasByBall: MatchStatsWrapper.deserializeMap(
        json.paceStrikeRateBiasByBall
      ),
      paceWicketBiasByBall: MatchStatsWrapper.deserializeMap(
        json.paceWicketBiasByBall
      ),
      pacePushByBall: MatchStatsWrapper.deserializeMap(json.pacePushByBall),
      spinStrikeRateBiasByBall: MatchStatsWrapper.deserializeMap(
        json.spinStrikeRateBiasByBall
      ),
      spinWicketBiasByBall: MatchStatsWrapper.deserializeMap(
        json.spinWicketBiasByBall
      ),
      spinPushByBall: MatchStatsWrapper.deserializeMap(json.spinPushByBall),
      overBowlerReservations:
        MatchStatsWrapper.deserializeOverBowlerReservations(
          json.overBowlerReservations
        ),
      surgeBowlerReservations: MatchStatsWrapper.deserializeStringArrayMap(
        json.surgeBowlerReservations
      ),
      groundWeight: json.groundWeight,
      teamWeights: MatchStatsWrapper.deserializeUuidToNumberMap(
        json.teamWeights
      ),
      batsmanMatchStrikeRateAdjustments:
        MatchStatsWrapper.deserializeTeamPlayerNumberMap(
          json.batsmanMatchStrikeRateAdjustments
        ),
      batsmanMatchWicketPercentAdjustments:
        MatchStatsWrapper.deserializeTeamPlayerNumberMap(
          json.batsmanMatchWicketPercentAdjustments
        ),
      bowlerMatchStrikeRateAdjustments:
        MatchStatsWrapper.deserializeTeamPlayerNumberMap(
          json.bowlerMatchStrikeRateAdjustments
        ),
      bowlerMatchWicketPercentAdjustments:
        MatchStatsWrapper.deserializeTeamPlayerNumberMap(
          json.bowlerMatchWicketPercentAdjustments
        ),
      bowlerMatchPushAdjustments:
        MatchStatsWrapper.deserializeTeamPlayerNumberMap(
          json.bowlerMatchPushAdjustments
        ),
      partnershipPushAdjustments:
        MatchStatsWrapper.deserializeTeamPlayerNumberMap(
          json.partnershipPushAdjustments
        ),
      spinMultipliers: json.spinMultipliers,
      paceMultipliers: json.paceMultipliers,
      overrideAheadAndBehindLimit: json.overrideAheadAndBehindLimit,
      aheadLimit: json.aheadLimit,
      behindLimit: json.behindLimit,
      homeAdvantageBattingStrikeRateBias:
        json.homeAdvantageBattingStrikeRateBias,
      homeAdvantageBattingWicketBias: json.homeAdvantageBattingWicketBias,
      homeAdvantageBowlingStrikeRateBias:
        json.homeAdvantageBowlingStrikeRateBias,
      homeAdvantageBowlingWicketBias: json.homeAdvantageBowlingWicketBias,
      homeTeamId: !!json.homeTeamId ? UUID.fromString(json.homeTeamId) : null,
      twmAtLastBallInnings1Team1: json.twmAtLastBallInnings1Team1,
      twmAdditionInnings1Team1: json.twmAdditionInnings1Team1,
      twmAtLastBallInnings2Team1: json.twmAtLastBallInnings2Team1,
      twmAdditionInnings2Team1: json.twmAdditionInnings2Team1,
      secondInningsPushAdjustmentTeam1: json.secondInningsPushAdjustmentTeam1,
      secondInningsPushDilutionTeam1: json.secondInningsPushDilutionTeam1,
      twmAtLastBallInnings1Team2: json.twmAtLastBallInnings1Team2,
      twmAdditionInnings1Team2: json.twmAdditionInnings1Team2,
      twmAtLastBallInnings2Team2: json.twmAtLastBallInnings2Team2,
      twmAdditionInnings2Team2: json.twmAdditionInnings2Team2,
      secondInningsPushAdjustmentTeam2: json.secondInningsPushAdjustmentTeam2,
      secondInningsPushDilutionTeam2: json.secondInningsPushDilutionTeam2,
      traderConfidence: TrafficLight[json.traderConfidence],
      powerplayAdjustmentsInnings1: json.powerplayAdjustmentsInnings1,
      powerplayAdjustmentsInnings2: json.powerplayAdjustmentsInnings2,
      playerTwmBallsUntilSet: json.playerTwmBallsUntilSet,
      playerTwmFirstBallMultiplier: json.playerTwmFirstBallMultiplier,
      playerTwmOtherBallMultiplier: json.playerTwmOtherBallMultiplier,
      playerTwmDivisor: json.playerTwmDivisor,
    };
  }

  public static serializeMatchStats(matchStats: MatchStats): any {
    return {
      strikeRateBiasByBall: MatchStatsWrapper.serializeMap(
        matchStats.strikeRateBiasByBall
      ),
      wicketBiasByBall: MatchStatsWrapper.serializeMap(
        matchStats.wicketBiasByBall
      ),
      pushByBall: MatchStatsWrapper.serializeMap(matchStats.pushByBall),
      paceStrikeRateBiasByBall: MatchStatsWrapper.serializeMap(
        matchStats.paceStrikeRateBiasByBall
      ),
      paceWicketBiasByBall: MatchStatsWrapper.serializeMap(
        matchStats.paceWicketBiasByBall
      ),
      pacePushByBall: MatchStatsWrapper.serializeMap(matchStats.pacePushByBall),
      spinStrikeRateBiasByBall: MatchStatsWrapper.serializeMap(
        matchStats.spinStrikeRateBiasByBall
      ),
      spinWicketBiasByBall: MatchStatsWrapper.serializeMap(
        matchStats.spinWicketBiasByBall
      ),
      spinPushByBall: MatchStatsWrapper.serializeMap(matchStats.spinPushByBall),
      overBowlerReservations: MatchStatsWrapper.serializeMapOfAnyToMap(
        matchStats.overBowlerReservations
      ),
      surgeBowlerReservations: MatchStatsWrapper.serializeMap(
        matchStats.surgeBowlerReservations
      ),
      groundWeight: matchStats.groundWeight,
      teamWeights: MatchStatsWrapper.serializeMap(matchStats.teamWeights),
      batsmanMatchStrikeRateAdjustments:
        MatchStatsWrapper.serializeMapOfAnyToMap(
          matchStats.batsmanMatchStrikeRateAdjustments
        ),
      batsmanMatchWicketPercentAdjustments:
        MatchStatsWrapper.serializeMapOfAnyToMap(
          matchStats.batsmanMatchWicketPercentAdjustments
        ),
      bowlerMatchStrikeRateAdjustments:
        MatchStatsWrapper.serializeMapOfAnyToMap(
          matchStats.bowlerMatchStrikeRateAdjustments
        ),
      bowlerMatchWicketPercentAdjustments:
        MatchStatsWrapper.serializeMapOfAnyToMap(
          matchStats.bowlerMatchWicketPercentAdjustments
        ),
      bowlerMatchPushAdjustments: MatchStatsWrapper.serializeMapOfAnyToMap(
        matchStats.bowlerMatchPushAdjustments
      ),
      partnershipPushAdjustments: MatchStatsWrapper.serializeMapOfAnyToMap(
        matchStats.partnershipPushAdjustments
      ),
      spinMultipliers: matchStats.spinMultipliers,
      paceMultipliers: matchStats.paceMultipliers,
      overrideAheadAndBehindLimit: matchStats.overrideAheadAndBehindLimit,
      aheadLimit: matchStats.aheadLimit,
      behindLimit: matchStats.behindLimit,
      homeAdvantageBattingStrikeRateBias:
        matchStats.homeAdvantageBattingStrikeRateBias,
      homeAdvantageBattingWicketBias: matchStats.homeAdvantageBattingWicketBias,
      homeAdvantageBowlingStrikeRateBias:
        matchStats.homeAdvantageBowlingStrikeRateBias,
      homeAdvantageBowlingWicketBias: matchStats.homeAdvantageBowlingWicketBias,
      homeTeamId: !!matchStats.homeTeamId ? matchStats.homeTeamId.value : null,
      twmAtLastBallInnings1Team1: matchStats.twmAtLastBallInnings1Team1,
      twmAdditionInnings1Team1: matchStats.twmAdditionInnings1Team1,
      twmAtLastBallInnings2Team1: matchStats.twmAtLastBallInnings2Team1,
      twmAdditionInnings2Team1: matchStats.twmAdditionInnings2Team1,
      secondInningsPushAdjustmentTeam1:
        matchStats.secondInningsPushAdjustmentTeam1,
      secondInningsPushDilutionTeam1: matchStats.secondInningsPushDilutionTeam1,
      twmAtLastBallInnings1Team2: matchStats.twmAtLastBallInnings1Team2,
      twmAdditionInnings1Team2: matchStats.twmAdditionInnings1Team2,
      twmAtLastBallInnings2Team2: matchStats.twmAtLastBallInnings2Team2,
      twmAdditionInnings2Team2: matchStats.twmAdditionInnings2Team2,
      secondInningsPushAdjustmentTeam2:
        matchStats.secondInningsPushAdjustmentTeam2,
      secondInningsPushDilutionTeam2: matchStats.secondInningsPushDilutionTeam2,
      traderConfidence: matchStats.traderConfidence,
      powerplayAdjustmentsInnings1: matchStats.powerplayAdjustmentsInnings1,
      powerplayAdjustmentsInnings2: matchStats.powerplayAdjustmentsInnings2,
      playerTwmBallsUntilSet: matchStats.playerTwmBallsUntilSet,
      playerTwmFirstBallMultiplier: matchStats.playerTwmFirstBallMultiplier,
      playerTwmOtherBallMultiplier: matchStats.playerTwmOtherBallMultiplier,
      playerTwmDivisor: matchStats.playerTwmDivisor,
    };
  }

  public static serializeMapOfAnyToMap(map: Map<any, Map<any, any>>): any {
    const result = {};
    map.forEach((value, key) => {
      result[key] = MatchStatsWrapper.serializeMap(value);
    });
    return result;
  }

  private static serializeMap(map: Map<any, any>): any {
    const result = {};
    map.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  }

  public static deserializeMap(json: any): Map<number, number> {
    const map: Map<number, number> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(Number(key), Number(value));
    }
    return map;
  }

  public static deserializeStringArrayMap(json: any): Map<string, string[]> {
    const map: Map<string, string[]> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(key, value as string[]);
    }
    return map;
  }

  public static deserializeUuidToNumberMap(json: any): Map<string, number> {
    const map: Map<string, number> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(key, Number(value));
    }
    return map;
  }

  public static deserializeTeamPlayerNumberMap(
    json: any
  ): Map<string, Map<string, number>> {
    const map: Map<string, Map<string, number>> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(key, MatchStatsWrapper.deserializeUuidToNumberMap(value));
    }
    return map;
  }

  public static deserializeOverBowlerReservations(
    json: any
  ): Map<string, Map<number, string>> {
    const map: Map<string, Map<number, string>> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(key, MatchStatsWrapper.deserializeStringMap(value));
    }
    return map;
  }

  public static deserializeStringMap(json: any): Map<number, string> {
    const map: Map<number, string> = new Map();
    for (const [key, value] of Object.entries(json)) {
      map.set(Number(key), value as string);
    }
    return map;
  }

  public static emptyWithName(name: string): MatchStatsWrapper {
    return new MatchStatsWrapper(
      null,
      null,
      null,
      null,
      this.createDefaultStats(
        services.currentGameService.currentMatchFormat,
        services.currentGameService.currentMatch
      )
    );
  }

  private static createDefaultStats(
    matchFormat: MatchFormat,
    match: Match
  ): MatchStats {
    const totalBalls = MatchFormat.getTotalBalls(matchFormat);
    const defaultBallByBallMap: Map<number, number> = new Map();
    defaultBallByBallMap.set(0, 1);
    defaultBallByBallMap.set(totalBalls, 1);
    const defaultBallByBallPushMap: Map<number, number> = new Map();
    defaultBallByBallPushMap.set(0, 0);
    defaultBallByBallPushMap.set(totalBalls, 0);
    const defaultTeamWeights: Map<string, number> = new Map();
    defaultTeamWeights.set(match?.team1Id.value, 1);
    defaultTeamWeights.set(match?.team2Id.value, 1);
    const defaultSurgeBowlerReservations: Map<string, string[]> = new Map();
    defaultSurgeBowlerReservations.set(match?.team1Id.value, []);
    defaultSurgeBowlerReservations.set(match?.team2Id.value, []);

    const defaultOverBowlerReservations = new Map();
    matchFormat?.inningsConfiguration.forEach((inningsConfiguration, innings) =>
      defaultOverBowlerReservations.set(innings, new Map())
    );

    const defaultTeamToUuidToNumberMap: Map<
      string,
      Map<string, number>
    > = new Map();
    defaultTeamToUuidToNumberMap.set(match?.team1Id.value, new Map());
    defaultTeamToUuidToNumberMap.set(match?.team2Id.value, new Map());

    const defaultPowerplayAdjustments: PowerplayAdjustments = {
      swingUpToBall: 0,
      swingPower: 0,
      swingExponent: 2,
      srMultiplier: 1,
      wicketMultiplier: 2,
      basePushAdjustment: 0.0,
      baseSrBias: 1.0,
      baseWicketBias: 1.0,
      powerplayPercentBiases: {
        boundaryToRunsBias: 1.0,
        fourToSixBias: 1.0,
        oneToTwoBias: 1.0,
        oneAndTwoToThreeBias: 1.0,
      },
    };

    return {
      strikeRateBiasByBall: defaultBallByBallMap,
      wicketBiasByBall: defaultBallByBallMap,
      pushByBall: defaultBallByBallPushMap,
      paceStrikeRateBiasByBall: defaultBallByBallMap,
      paceWicketBiasByBall: defaultBallByBallMap,
      pacePushByBall: defaultBallByBallPushMap,
      spinStrikeRateBiasByBall: defaultBallByBallMap,
      spinWicketBiasByBall: defaultBallByBallMap,
      spinPushByBall: defaultBallByBallPushMap,
      overBowlerReservations: defaultOverBowlerReservations,
      surgeBowlerReservations: defaultSurgeBowlerReservations,
      groundWeight: 1,
      teamWeights: defaultTeamWeights,
      batsmanMatchStrikeRateAdjustments: defaultTeamToUuidToNumberMap,
      batsmanMatchWicketPercentAdjustments: defaultTeamToUuidToNumberMap,
      bowlerMatchStrikeRateAdjustments: defaultTeamToUuidToNumberMap,
      bowlerMatchWicketPercentAdjustments: defaultTeamToUuidToNumberMap,
      bowlerMatchPushAdjustments: defaultTeamToUuidToNumberMap,
      partnershipPushAdjustments: defaultTeamToUuidToNumberMap,
      overrideAheadAndBehindLimit: true,
      spinMultipliers: [
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
      ],
      paceMultipliers: [
        [1, 1, 1, 1, 1],
        [1, 1, 1, 1, 1],
      ],
      aheadLimit: 10,
      behindLimit: 10,
      homeAdvantageBattingStrikeRateBias: 1,
      homeAdvantageBattingWicketBias: 1,
      homeAdvantageBowlingStrikeRateBias: 1,
      homeAdvantageBowlingWicketBias: 1,
      homeTeamId: null,
      twmAtLastBallInnings1Team1: 2.7,
      twmAdditionInnings1Team1: 0.0,
      twmAtLastBallInnings2Team1: 2.7,
      twmAdditionInnings2Team1: 0.0,
      secondInningsPushAdjustmentTeam1: 0.5,
      secondInningsPushDilutionTeam1: 0.0,
      twmAtLastBallInnings1Team2: 2.7,
      twmAdditionInnings1Team2: 0.0,
      twmAtLastBallInnings2Team2: 2.7,
      twmAdditionInnings2Team2: 0.0,
      secondInningsPushAdjustmentTeam2: 0.5,
      secondInningsPushDilutionTeam2: 0.0,
      traderConfidence: TrafficLight.RED,
      powerplayAdjustmentsInnings1: defaultPowerplayAdjustments,
      powerplayAdjustmentsInnings2: defaultPowerplayAdjustments,
      playerTwmBallsUntilSet: 0,
      playerTwmFirstBallMultiplier: 2.39,
      playerTwmOtherBallMultiplier: 2.3,
      playerTwmDivisor: 200,
    };
  }

  public static clone(originalStats: MatchStatsWrapper): MatchStatsWrapper {
    return !!originalStats
      ? MatchStatsWrapper.deserializeOne(
          MatchStatsWrapper.serialize(originalStats)
        )
      : null;
  }

  public static getTypeName(): string {
    return "matchStats";
  }

  public toString(): string {
    return "Match Stats";
  }
}

export const readableMatchStatsByBallNames: Record<string, string> = {
  strikeRateBiasByBall: "Strike Rate Bias",
  paceStrikeRateBiasByBall: "Pace Strike Rate Bias",
  spinStrikeRateBiasByBall: "Spin Strike Rate Bias",
  wicketBiasByBall: "Wicket Bias",
  paceWicketBiasByBall: "Pace Wicket Bias",
  spinWicketBiasByBall: "Spin Wicket Bias",
  pushByBall: "Push Adjustment",
  pacePushByBall: "Pace Push Adjustment",
  spinPushByBall: "Spin Push Adjustment",
};

// [min, max, step, dp, default for new point] for each type of "by ball" property
const BIAS_MIN = 0;
const BIAS_MAX = 2;
const BIAS_STEP = 0.001;
const BIAS_DP = 3;
const BIAS_DEFAULT = 1;

const PUSH_ADJUST_MIN = -100;
const PUSH_ADJUST_MAX = 100;
const PUSH_ADJUST_STEP = 1;
const PUSH_ADJUST_DP = 0;
const PUSH_ADJUST_DEFAULT = 0;

export const rangeProperties: Record<string, number[]> = {
  strikeRateBiasByBall: [BIAS_MIN, BIAS_MAX, BIAS_STEP, BIAS_DP, BIAS_DEFAULT],
  paceStrikeRateBiasByBall: [
    BIAS_MIN,
    BIAS_MAX,
    BIAS_STEP,
    BIAS_DP,
    BIAS_DEFAULT,
  ],
  spinStrikeRateBiasByBall: [
    BIAS_MIN,
    BIAS_MAX,
    BIAS_STEP,
    BIAS_DP,
    BIAS_DEFAULT,
  ],
  wicketBiasByBall: [BIAS_MIN, BIAS_MAX, BIAS_STEP, BIAS_DP, BIAS_DEFAULT],
  paceWicketBiasByBall: [BIAS_MIN, BIAS_MAX, BIAS_STEP, BIAS_DP, BIAS_DEFAULT],
  spinWicketBiasByBall: [BIAS_MIN, BIAS_MAX, BIAS_STEP, BIAS_DP, BIAS_DEFAULT],
  pushByBall: [
    PUSH_ADJUST_MIN,
    PUSH_ADJUST_MAX,
    PUSH_ADJUST_STEP,
    PUSH_ADJUST_DP,
    PUSH_ADJUST_DEFAULT,
  ],
  pacePushByBall: [
    PUSH_ADJUST_MIN,
    PUSH_ADJUST_MAX,
    PUSH_ADJUST_STEP,
    PUSH_ADJUST_DP,
    PUSH_ADJUST_DEFAULT,
  ],
  spinPushByBall: [
    PUSH_ADJUST_MIN,
    PUSH_ADJUST_MAX,
    PUSH_ADJUST_STEP,
    PUSH_ADJUST_DP,
    PUSH_ADJUST_DEFAULT,
  ],
};
