import { Button } from "@mui/material";
import { Component, ReactNode } from "react";

import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import { InningsStatisticType } from "../../types/enums/statistic-type";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { UUID } from "../../types/uuid";
import { propsEqual } from "../component-utils";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

import { BowlerReservationModal } from "./bowler-reservation-modal";

interface Props {
  originalStats: MatchStatsWrapper;
  matchFormat: MatchFormat;
  gameState: GameState;
  bowlingTeam: Player[];
  teamId: UUID;
  innings: number;
  onUpdate: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
  disabled: boolean;
}

interface State {
  gameStateAndReservationsCombined: Map<number, string[]>;
  modalOpen: boolean;
  currentStats: MatchStatsWrapper;
}

export class BowlerReservationInningsComponent extends Component<Props, State> {
  private static readonly DEFAULT_STATE = {
    gameStateAndReservationsCombined: null,
    modalOpen: false,
    currentStats: null,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...BowlerReservationInningsComponent.DEFAULT_STATE,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private updateState() {
    this.setState({
      currentStats: MatchStatsWrapper.clone(this.props.originalStats),
      gameStateAndReservationsCombined:
        this.combineMatchStatsAndGameStateReservation(),
    });
  }

  private findFirstUnreservedOver(): number {
    let firstUnreservedOver = null;
    let found = false;
    this.state.gameStateAndReservationsCombined &&
      this.props.matchFormat.overConfiguration[this.props.innings - 1].forEach(
        (ballsInOver, over) => {
          if (
            !found &&
            this.state.gameStateAndReservationsCombined.get(over) === undefined
          ) {
            firstUnreservedOver = over;
            found = true;
          }
        }
      );
    return firstUnreservedOver;
  }

  private combineMatchStatsAndGameStateReservation(): Map<number, string[]> {
    const combinedMap: Map<number, string[]> = new Map();
    const fromGameState: Map<number, UUID[]> =
      this.props.gameState &&
      this.props.gameState.inningsStats &&
      this.props.gameState.inningsStats[InningsStatisticType.OVER_BOWLERS] &&
      this.props.gameState.inningsStats[InningsStatisticType.OVER_BOWLERS]
        .length >= this.props.innings
        ? this.props.gameState.inningsStats[InningsStatisticType.OVER_BOWLERS][
            this.props.innings - 1
          ]
        : new Map();
    let lastGameStateOver = -1;

    fromGameState.forEach((uuidArray, over) => {
      if (uuidArray.length > 0) {
        combinedMap.set(
          over + 1,
          uuidArray.map((u) => u.value)
        );
        lastGameStateOver = over + 1;
      }
    });

    if (this.props.gameState.innings === this.props.innings) {
      const currentOver =
        this.props.gameState.calculateOverParts(
          this.props.gameState.ballNumber,
          this.props.gameState.innings
        )[0] + 1;
      if (!!this.props.gameState.bowler) {
        if (!combinedMap.get(currentOver)) {
          combinedMap.set(currentOver, [this.props.gameState.bowler.value]);
        } else if (
          combinedMap
            .get(currentOver)
            .find(
              (playerId) => playerId === this.props.gameState.bowler.value
            ) === undefined
        ) {
          combinedMap.get(currentOver).push(this.props.gameState.bowler.value);
        }
      }
    }

    if (!!this.props.originalStats && !!this.props.originalStats.matchStats) {
      const inningsReservations: Map<number, string> =
        this.props.originalStats.matchStats.overBowlerReservations.get(
          this.props.teamId?.value
        );
      inningsReservations?.forEach((playerId, over) => {
        if (over > lastGameStateOver) {
          combinedMap.set(over, [playerId]);
        }
      });
    }

    return combinedMap;
  }

  private removeReservation(over: number) {
    const inningsReservations: Map<number, string> =
      this.state.currentStats.matchStats.overBowlerReservations.get(
        this.props.teamId?.value
      );
    inningsReservations?.delete(over);
    this.props.onUpdate(this.state.currentStats, true);
  }

  private updateInningsMap(over: number, playerId: string) {
    const inningsReservations: Map<number, string> =
      this.state.currentStats.matchStats.overBowlerReservations.get(
        this.props.teamId?.value
      );
    inningsReservations?.set(over, playerId);
    this.setState({ modalOpen: false });
    this.props.onUpdate(this.state.currentStats, true);
  }

  public render() {
    const reservations: ReactNode[] = [];
    this.state.currentStats &&
      this.state.currentStats.matchStats.overBowlerReservations
        .get(this.props.teamId?.value)
        ?.forEach((playerId, over) => {
          const player: Player =
            this.props.bowlingTeam &&
            this.props.bowlingTeam.find(
              (player) => player.playerId.value === playerId
            );
          reservations.push(
            <div
              key={`reservation-row-${over}`}
              className="bowler-reservation-row"
            >
              <div>Over {over}</div>
              <div>{player.longName}</div>
              <div className="bowler-reservation-player-view-and-buttons">
                <PlayerView
                  imgClasses={"scorecard-image"}
                  player={player}
                  canEdit={false}
                  canDelete={false}
                  showName={false}
                  imgHeight={80}
                  imgWidth={60}
                  width={"60px"}
                />
                <div className="bowler-reservation-buttons">
                  {!this.props.disabled && (
                    <TooltipIconButton
                      onClick={() => this.removeReservation(over)}
                      icon="delete"
                      title="Remove Reservation"
                      colour="#888888"
                    />
                  )}
                </div>
              </div>
            </div>
          );
        });

    return (
      <div className="bowler-reservation-innings-component">
        {!this.props.disabled && (
          <Button
            onClick={() => this.setState({ modalOpen: true })}
            variant="contained"
            color="primary"
            disabled={this.findFirstUnreservedOver() == null}
          >
            Add Reservation
          </Button>
        )}
        <BowlerReservationModal
          originalStats={this.state.currentStats}
          matchFormat={this.props.matchFormat}
          gameState={this.props.gameState}
          players={this.props.bowlingTeam}
          innings={this.props.innings}
          teamId={this.props.teamId}
          open={this.state.modalOpen}
          gameStateAndReservationsCombined={
            this.state.gameStateAndReservationsCombined
          }
          onCancel={() => this.setState({ modalOpen: false })}
          onProceed={(over, playerId) => this.updateInningsMap(over, playerId)}
        />
        {reservations.length > 0 && reservations}
        {reservations.length === 0 && <div>No bowler reservations</div>}
      </div>
    );
  }
}
