export enum BetSource {
  BETFAIR = "BETFAIR",
  WINNER_7 = "WINNER_7",
  ORBIT = "ORBIT",
}

export const betSourceReadableNames: Record<BetSource, string> = {
  BETFAIR: "Betfair",
  WINNER_7: "Winner 7",
  ORBIT: "Orbit",
};
