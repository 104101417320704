import { Chip, Switch } from "@mui/material";
import { CSSProperties, Fragment, useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { Series } from "../../types/entities/series";
import { Team } from "../../types/entities/team";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import {
  AdminPreferences,
  LeagueStrengthSelection,
  LeagueStrengthSettings,
} from "../../types/preferences/admin-preferences";
import { services, showConfirmationDialog } from "../../types/services";
import { UUID } from "../../types/uuid";
import { observableFrom } from "../component-utils";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  adminPreferences: AdminPreferences;
  onChange: (updatedSettings: LeagueStrengthSettings[]) => void;
}
export default function BowlingTypeByPhaseMultipliersComponent({
  adminPreferences,
  onChange,
}: Readonly<Props>): React.JSX.Element {
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [allSeries, setAllSeries] = useState<Series[]>([]);
  const [expandedRow, setExpandedRow] = useState<number>(-1);
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);

  useEffect(() => {
    const subscriptions: Subscription[] = [];
    subscriptions.push(
      services.teamService.internationalTeamsSubject.subscribe(
        (teams: Team[]) => setAllTeams(teams)
      )
    );
    subscriptions.push(
      services.seriesService
        .getAll()
        .subscribe((series: Series[]) => setAllSeries(series))
    );

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  useEffect(() => {
    const selectedTeamIds: string[] =
      adminPreferences.leagueStrengthSettings.flatMap(
        (leagueStrengthSetting) =>
          leagueStrengthSetting.selection.internationalOppositions
      );
    setSelectedTeams(
      allTeams.filter((team) =>
        selectedTeamIds.find((id) => team.teamId.value === id)
      )
    );
  }, [allTeams, adminPreferences]);

  const filterTeamsForMatchType = (
    teams: Team[],
    matchType: MatchType,
    indexToExclude: number = null
  ) => {
    const teamsInUse: [string, MatchType][] =
      adminPreferences.leagueStrengthSettings.flatMap((setting, index) => {
        if (indexToExclude === index) {
          return [];
        }

        return setting.selection.internationalOppositions.map(
          (internationalOpposition) =>
            [
              internationalOpposition,
              setting.selection.internationalMatchType,
            ] as [string, MatchType]
        );
      });

    return teams.filter(
      (team) =>
        teamsInUse.find(
          (teamInUse) =>
            teamInUse[0] === team.teamId.value && teamInUse[1] === matchType
        ) === undefined
    );
  };

  const filterLeagues = (series: Series[]) => {
    const seriesInUse: string[] =
      adminPreferences.leagueStrengthSettings.flatMap(
        (setting) => setting.selection.domesticLeagues
      );
    const uniqueSeriesNames = new Set();
    return series.filter((serie) =>
      seriesInUse.find((serieInUse) => serieInUse === serie.name) ===
        undefined && !uniqueSeriesNames.has(serie.name)
        ? uniqueSeriesNames.add(serie.name)
        : false
    );
  };

  const getTeamName = (teamId: string) => {
    return selectedTeams.find((team) => teamId === team.teamId.value)?.name;
  };

  const toString = (settings: LeagueStrengthSettings) => {
    return settings.selection.international
      ? "international-" + settings.selection.internationalOppositions.join("-")
      : "domestic-" + settings.selection.domesticLeagues.join("-");
  };

  const selectionColumnStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  const selectorStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    justifyContent: "center",
  };

  const chipStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    gap: "2px",
    justifyContent: "center",
  };

  const buildSelectionTableCellsExpanded = (
    selection: LeagueStrengthSelection,
    index: number
  ) => {
    return (
      <>
        <Switch
          checked={selection.international}
          onChange={() => toggleInternational(index)}
        />
        {selection.international && (
          <div style={selectionColumnStyle}>
            <div style={selectorStyle}>
              <EntityAutoSelector
                label=""
                classes="auto-select autocomplete"
                placeholder="Add Opposition"
                options={observableFrom(
                  filterTeamsForMatchType(
                    allTeams,
                    selection.internationalMatchType
                  )
                )}
                optionService={services.teamService}
                onSelect={(entity: Team) =>
                  addInternationalOpposition(index, entity.teamId)
                }
                canEdit={false}
                canAddNew={false}
              />
              <EnumSelector
                enumObject={MatchType}
                value={selection.internationalMatchType}
                readableValues={matchTypeNames}
                onSelect={(matchType) =>
                  setInternationalMatchType(index, matchType)
                }
                canClear={false}
                canType={false}
              />
            </div>
            <div style={chipStyle}>
              {selection.internationalOppositions.map((team, teamIndex) => (
                <Chip
                  label={getTeamName(team)}
                  key={team}
                  variant="outlined"
                  onDelete={() =>
                    removeInternationalOpposition(index, teamIndex)
                  }
                />
              ))}
            </div>
          </div>
        )}
        {!selection.international && (
          <div style={selectionColumnStyle}>
            <div style={selectorStyle}>
              <EntityAutoSelector
                label=""
                classes="auto-select autocomplete"
                placeholder="Add League"
                options={observableFrom(filterLeagues(allSeries))}
                optionService={services.seriesService}
                onSelect={(entity: Series) =>
                  addDomesticLeague(index, entity.name)
                }
                renderer={(props, option) => (
                  <div {...props}>{option.object.name}</div>
                )}
                canEdit={false}
                canAddNew={false}
              />
            </div>
            <div style={chipStyle}>
              {selection.domesticLeagues.map((series, seriesIndex) => (
                <Chip
                  label={series}
                  key={series}
                  variant="outlined"
                  onDelete={() => removeDomesticLeague(index, seriesIndex)}
                />
              ))}
            </div>
          </div>
        )}
      </>
    );
  };

  const buildSelectionTableCellsCollapsed = (
    selection: LeagueStrengthSelection,
    index: number
  ) => {
    return (
      <>
        <Switch
          checked={selection.international}
          onChange={() => toggleInternational(index)}
        />
        {selection.international && (
          <div style={selectionColumnStyle}>
            <div>{matchTypeNames[selection.internationalMatchType]} vs</div>
            <div>
              {selection.internationalOppositions
                .map((teamId) =>
                  selectedTeams.find((t) => t.teamId.value === teamId)
                )
                .filter((t) => t !== undefined)
                .map((t) => t.name)
                .join(", ")}
            </div>
          </div>
        )}
        {!selection.international && (
          <div style={selectionColumnStyle}>
            {selection.domesticLeagues.join(", ")}
          </div>
        )}
      </>
    );
  };

  const addRow = () => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings.push({
      selection: {
        international: false,
        internationalOppositions: [],
        domesticLeagues: [],
        internationalMatchType: MatchType.T20_AND_HUNDRED,
      },
      paceStrikeRateBias: 1.0,
      paceWicketBias: 1.0,
      spinStrikeRateBias: 1.0,
      spinWicketBias: 1.0,
    });
    onChange(currentSettings);
    setExpandedRow(currentSettings.length - 1);
  };

  const removeRow = (index: number) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings.splice(index, 1);
    onChange(currentSettings);
  };

  const toggleInternational = (index: number) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings[index].selection.international =
      !currentSettings[index].selection.international;
    onChange(currentSettings);
  };

  const setInternationalMatchType = (index: number, matchType: MatchType) => {
    validateInternationalMatchType(index, matchType, (index, matchType) => {
      const currentSettings = adminPreferences.leagueStrengthSettings;
      currentSettings[index].selection.internationalMatchType = matchType;
      onChange(currentSettings);
    });
  };

  const validateInternationalMatchType = (
    index: number,
    matchType: MatchType,
    onValid: (index: number, matchType: MatchType) => void
  ) => {
    const badTeams: string[] = findInternationalTeamsWithMatchTypeSettings(
      index,
      matchType
    );

    if (badTeams.length === 0) {
      onValid(index, matchType);
    } else {
      showConfirmationDialog(
        () => {},
        "Selection Invalid",
        "The following teams already have settings for " +
          matchTypeNames[matchType] +
          ": " +
          badTeams.map((teamId) => getTeamName(teamId)).join(", "),
        "OK",
        true
      );
    }
  };

  const findInternationalTeamsWithMatchTypeSettings = (
    index: number,
    matchType: MatchType
  ): string[] => {
    // teams which may still be assigned settings for this match type
    const allowedTeams: Team[] = filterTeamsForMatchType(
      allTeams,
      matchType,
      index
    );

    // teams about to be assigned settings for this match type
    const currentlySelectedTeams: string[] =
      adminPreferences.leagueStrengthSettings[index].selection
        .internationalOppositions;

    // if any of the teams about to be assigned may not be assigned, add them to the list of bad teams
    const badTeams: string[] = [];
    currentlySelectedTeams.forEach((teamId) => {
      if (
        allowedTeams.find((team) => team.teamId.value === teamId) === undefined
      ) {
        badTeams.push(teamId);
      }
    });

    return badTeams;
  };

  const addInternationalOpposition = (index: number, teamId: UUID) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings[index].selection.internationalOppositions.push(
      teamId.value
    );
    onChange(currentSettings);
  };

  const removeInternationalOpposition = (index: number, teamIndex: number) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings[index].selection.internationalOppositions.splice(
      teamIndex,
      1
    );
    onChange(currentSettings);
  };

  const addDomesticLeague = (index: number, league: string) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings[index].selection.domesticLeagues.push(league);
    onChange(currentSettings);
  };

  const removeDomesticLeague = (index: number, seriesIndex: number) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings[index].selection.domesticLeagues.splice(seriesIndex, 1);
    onChange(currentSettings);
  };

  const updateValue = (index: number, property: string, value: number) => {
    const currentSettings = adminPreferences.leagueStrengthSettings;
    currentSettings[index][property] = value;
    onChange(currentSettings);
  };

  const numberSelectorConfig = [
    {
      title: "Pace SR Bias",
      property: "paceStrikeRateBias",
    },
    {
      title: "Pace W% Bias",
      property: "paceWicketBias",
    },
    {
      title: "Spin SR Bias",
      property: "spinStrikeRateBias",
    },
    {
      title: "Spin W% Bias",
      property: "spinWicketBias",
    },
  ];

  const titleStyle = { display: "flex", justifyContent: "center" };

  return (
    <>
      <div
        className="admin-settings-default-content-section-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>League Strength Settings</div>
        <TooltipIconButton title="Add settings" onClick={addRow} icon="add" />
      </div>
      <div className="league-strength-multipliers">
        <div style={titleStyle}>International?</div>
        <div style={titleStyle}>Leagues/Opposition</div>
        {numberSelectorConfig.map((config) => (
          <div style={titleStyle} key={config.property}>
            {config.title}
          </div>
        ))}
        <div></div>
        {adminPreferences.leagueStrengthSettings.map((settings, index) => (
          <Fragment key={toString(settings)}>
            {index === expandedRow && (
              <>
                {buildSelectionTableCellsExpanded(settings.selection, index)}
                {numberSelectorConfig.map((config) => (
                  <div style={{ padding: "0px" }} key={config.property}>
                    <NumberSelector
                      style={{ justifyContent: "center" }}
                      textFieldStyle={{
                        paddingLeft: "5px",
                        width: "100%",
                      }}
                      min={0}
                      max={2}
                      step={0.01}
                      decimalPlaces={2}
                      initial={settings[config.property]}
                      onValid={(valid: number) =>
                        updateValue(index, config.property, valid)
                      }
                    />
                  </div>
                ))}
                <div style={{ display: "flex" }}>
                  <TooltipIconButton
                    title="done"
                    onClick={() => setExpandedRow(-1)}
                    icon="done_circle"
                  />
                  <TooltipIconButton
                    title="Remove"
                    onClick={() => removeRow(index)}
                    icon="delete_circle"
                  />
                </div>
              </>
            )}

            {index !== expandedRow && (
              <>
                {buildSelectionTableCellsCollapsed(settings.selection, index)}
                {numberSelectorConfig.map((config) => (
                  <div style={{ padding: "0px" }} key={config.property}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {settings[config.property]}
                    </div>
                  </div>
                ))}
                <div style={{ display: "flex" }}>
                  <TooltipIconButton
                    title="edit"
                    onClick={() => setExpandedRow(index)}
                    icon="edit_circle"
                  />
                  <TooltipIconButton
                    title="Remove"
                    onClick={() => removeRow(index)}
                    icon="delete_circle"
                  />
                </div>
              </>
            )}
          </Fragment>
        ))}
      </div>
    </>
  );
}
