import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { UUID } from "../../types/uuid";

type PlayerStatsMap = Map<string, PlayerStatsWrapper>;

type Stats =
  | GroundStatsWrapper
  | MatchStatsWrapper
  | PlayerStatsWrapper
  | PlayerStatsMap;

type StatsKey = "groundStats" | "matchStats" | "playerStats";

function isObject(target): boolean {
  return target !== null && typeof target === "object";
}

function deepValueEqualityCheck(firstObject, secondObject): boolean {
  if (firstObject === secondObject) {
    return true;
  }

  if (firstObject instanceof Map && secondObject instanceof Map) {
    if (firstObject.size !== secondObject.size) return false;
    let isEqual = true;
    firstObject.forEach((value, key) => {
      if (!isEqual) {
        return;
      }
      if (
        !secondObject.has(key) ||
        !deepValueEqualityCheck(value, secondObject.get(key))
      ) {
        isEqual = false;
      }
    });
    return isEqual;
  }

  if (Array.isArray(firstObject) && Array.isArray(secondObject)) {
    if (firstObject.length !== secondObject.length) return false;
    return firstObject.every((element, index) =>
      deepValueEqualityCheck(element, secondObject[index])
    );
  }

  if (isObject(firstObject) && isObject(secondObject)) {
    const firstObjectKeys = Object.keys(firstObject);
    const secondObjectKeys = Object.keys(secondObject);

    if (firstObjectKeys.length !== secondObjectKeys.length) return false;

    return firstObjectKeys.every(
      (key) =>
        secondObjectKeys.includes(key) &&
        deepValueEqualityCheck(firstObject[key], secondObject[key])
    );
  }

  return false;
}

export function areStatsEqual(
  currentStats: Stats,
  comparedStats: Stats,
  properties: string[],
  statsKey: StatsKey,
  index: number = -1
): boolean {
  if (!currentStats || !comparedStats) {
    return false;
  }

  if (currentStats instanceof Map && comparedStats instanceof Map) {
    for (let [key, value] of currentStats) {
      if (!comparedStats.has(key)) {
        return false;
      }

      const comparedValue = comparedStats.get(key);

      for (const property of properties) {
        const currentPropertyValue =
          value[statsKey] && index !== -1
            ? value[statsKey][property][index]
            : value[statsKey][property];
        const comparedPropertyValue =
          comparedValue[statsKey] && index !== -1
            ? comparedValue[statsKey][property][index]
            : comparedValue[statsKey][property];

        if (
          !deepValueEqualityCheck(currentPropertyValue, comparedPropertyValue)
        ) {
          return false;
        }
      }
    }
    return true;
  }

  return properties.every((property) => {
    const currentPropertyValue =
      index !== -1
        ? currentStats[statsKey][property][index]
        : currentStats[statsKey][property];
    const comparedPropertyValue =
      index !== -1
        ? comparedStats[statsKey][property][index]
        : comparedStats[statsKey][property];

    return deepValueEqualityCheck(currentPropertyValue, comparedPropertyValue);
  });
}

export function getToolTipMessage(
  currentStats: Stats,
  comparedStats: Stats,
  properties: string[],
  comparedUserName: string,
  statsKey: StatsKey,
  index: number = -1
): string {
  if (areStatsEqual(currentStats, comparedStats, properties, statsKey, index)) {
    return `${comparedUserName} has the same stats as you, there is nothing to steal!`;
  }

  return `Steal stats from ${comparedUserName}`;
}

export function isOverReserved(
  playerId: UUID,
  over: number,
  teamId: UUID,
  matchStats: MatchStatsWrapper
): boolean {
  const reservations = matchStats.matchStats.overBowlerReservations.get(
    teamId?.value
  );

  if (!reservations) {
    return false;
  }

  return reservations.get(over) === playerId.value;
}
