import { MatchType } from "../enums/match-type";
import { UUID } from "../uuid";
import { Entity } from "./entity";

export class Match implements Entity {
  public entityId: UUID;
  public matchId: UUID;
  public team1Id: UUID;
  public team2Id: UUID;
  public motm: UUID;
  public groundId: UUID;
  public seriesId: UUID;
  public overs: number;
  public date: number;
  public createdBy: UUID;
  public createdAt: number;
  public matchFormatId: UUID;
  public timestamp: number;
  public matchType: MatchType;
  public simsEnabled: boolean;
  public espnNumNonUnique: number;

  constructor(
    entityId: UUID,
    matchId: UUID,
    team1Id: UUID,
    team2Id: UUID,
    motm: UUID,
    groundId: UUID,
    seriesId: UUID,
    overs: number,
    date: number,
    createdBy: UUID,
    createdAt: number,
    matchFormatId: UUID,
    timestamp: number,
    matchType: MatchType,
    simsEnabled: boolean,
    espnNumNonUnique: number
  ) {
    this.entityId = entityId;
    this.matchId = matchId;
    this.team1Id = team1Id;
    this.team2Id = team2Id;
    this.motm = motm;
    this.groundId = groundId;
    this.seriesId = seriesId;
    this.overs = overs;
    this.date = date;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.matchFormatId = matchFormatId;
    this.timestamp = timestamp;
    this.matchType = matchType;
    this.simsEnabled = simsEnabled;
    this.espnNumNonUnique = espnNumNonUnique;
  }

  public toString(): string {
    return `match: ${this.matchId}`;
  }

  public static deserializeList(json: any) {
    const matches: Match[] = [];
    json.forEach((element) => {
      matches.push(this.deserializeOne(element));
    });
    return matches;
  }

  public static deserializeOne(responseJSON: any): Match {
    return new Match(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.matchId),
      UUID.fromString(responseJSON.team1Id),
      UUID.fromString(responseJSON.team2Id),
      UUID.fromString(responseJSON.motm),
      UUID.fromString(responseJSON.groundId),
      UUID.fromString(responseJSON.seriesId),
      responseJSON.overs,
      responseJSON.date,
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      UUID.fromString(responseJSON.matchFormatId),
      responseJSON.timestamp,
      MatchType[responseJSON.matchType],
      responseJSON.simsEnabled,
      responseJSON.espnNumNonUnique
    );
  }

  public static serialize(match: Match): any {
    return {
      entityId: match.entityId === null ? null : match.entityId.value,
      matchId: match.matchId === null ? null : match.matchId.value,
      team1Id: match.team1Id === null ? null : match.team1Id.value,
      team2Id: match.team2Id === null ? null : match.team2Id.value,
      motm: match.motm === null ? null : match.motm.value,
      groundId: match.groundId === null ? null : match.groundId.value,
      seriesId: match.seriesId === null ? null : match.seriesId.value,
      overs: match.overs,
      date: match.date,
      createdBy: match.createdBy === null ? null : match.createdBy.value,
      createdAt: match.createdAt,
      matchFormatId:
        match.matchFormatId === null ? null : match.matchFormatId.value,
      timestamp: match.timestamp,
      matchType: match.matchType,
      simsEnabled: match.simsEnabled,
      espnNumNonUnique: match.espnNumNonUnique,
    };
  }

  public static emptyWithName(name: string): Match {
    return new Match(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      Date.now(),
      null,
      null,
      null,
      Date.now(),
      MatchType.T20_AND_HUNDRED,
      true,
      null
    );
  }

  public static getTypeName(): string {
    return "match";
  }
}
