import { Chip, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import TimeAgo from "timeago-react";
import { MatchFormat } from "../../types/entities/match-format";
import { Series } from "../../types/entities/series";
import { Team } from "../../types/entities/team";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import {
  AdminPreferencesField,
  MatchSpecificSettings,
  PowerplayAdjustments,
} from "../../types/preferences/admin-preferences";
import { services } from "../../types/services";
import { UUID } from "../../types/uuid";
import { observableFrom } from "../component-utils";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import PowerplaySettingsComponent from "../match-stats-page/powerplay-settings-component";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { bowlingTypeByPhaseMultipliersConfig } from "./admin-settings-page";
import BowlingTypeByPhaseMultipliersComponent from "./bowling-type-by-phase-multipliers-component";
import { ConfidenceSettingTable } from "./confidence-setting-table";

interface Props {
  initialSettings: MatchSpecificSettings;
  onUpdate: (matchSpecificSettings: MatchSpecificSettings) => void;
  onDelete?: () => void;
  onExpand?: () => void;
  expanded?: boolean;
  isDefault: boolean;
}

export const powerplayPercentsExpansionButtonStyle = {
  border: "none",
  padding: "2px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

const MATCH_SPECIFIC_SETTINGS_DEFAULT_DROP_DOWN_SIZE = 20;

export type MatchSpecificSettingsTopLevelField =
  | "name"
  | "twmAtLastBallInnings1"
  | "twmAdditionInnings1"
  | "twmAtLastBallInnings2"
  | "twmAdditionInnings2"
  | "secondInningsPushAdjustment"
  | "secondInningsPushDilution"
  | "surgeStrikeRateBiasInnings1"
  | "surgeWicketBiasInnings1"
  | "surgeStrikeRateBiasInnings2"
  | "surgeWicketBiasInnings2"
  | "spinMultipliersByPhase"
  | "paceMultipliersByPhase"
  | "playerTwmBallsUntilSet"
  | "playerTwmFirstBallMultiplier"
  | "playerTwmOtherBallMultiplier"
  | "playerTwmDivisor";

export default function MatchSpecificSettingsComponent({
  initialSettings,
  onUpdate,
  onDelete,
  onExpand,
  expanded,
  isDefault,
}: Readonly<Props>): React.JSX.Element {
  const [name, setName] = useState<string>(initialSettings.name);
  const [allTeams, setAllTeams] = useState<Team[]>([]);
  const [allSeries, setAllSeries] = useState<Series[]>([]);
  const [allFormats, setAllFormats] = useState<MatchFormat[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<Team[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<Series[]>([]);
  const [selectedFormats, setSelectedFormats] = useState<MatchFormat[]>([]);
  const [renaming, setRenaming] = useState<boolean>(false);

  useEffect(() => {
    const subscriptions: Subscription[] = [];
    subscriptions.push(services.teamService.getAll().subscribe(setAllTeams));
    subscriptions.push(services.seriesService.getAll().subscribe(setAllSeries));
    subscriptions.push(
      services.matchFormatService.getAll().subscribe(setAllFormats)
    );

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  useEffect(() => {
    const selectedTeamIds: UUID[] = initialSettings.teams;
    const selectedFormatIds: UUID[] = initialSettings.matchFormats;
    const selectedSeriesIds: UUID[] = initialSettings.series;
    setSelectedFormats(
      allFormats.filter((format) =>
        selectedFormatIds.find((id) => format.matchFormatId.value === id.value)
      )
    );
    setSelectedSeries(
      allSeries.filter((series) =>
        selectedSeriesIds.find((id) => series.seriesId.value === id.value)
      )
    );
    setSelectedTeams(
      allTeams.filter((team) =>
        selectedTeamIds.find((id) => team.teamId.value === id.value)
      )
    );
  }, [allTeams, allSeries, allFormats, initialSettings]);

  function update(settings: MatchSpecificSettings) {
    if (valid()) {
      onUpdate(settings);
    }
  }

  function updateConfidences(type: string, field: string, value: number) {
    update({
      ...initialSettings,
      adminConfidences: {
        ...initialSettings.adminConfidences,
        [type]: {
          ...initialSettings.adminConfidences[type],
          [field]: value,
        },
      },
    });
  }

  function updateField(
    field: MatchSpecificSettingsTopLevelField,
    value: AdminPreferencesField
  ) {
    update({
      ...initialSettings,
      [field]: value,
    });
  }

  function updatePowerplayAdjustments(
    powerplayAdjustments: PowerplayAdjustments
  ) {
    update({
      ...initialSettings,
      powerplayAdjustments,
    });
  }

  function deleteSettings() {
    onDelete();
  }

  function valid(): boolean {
    return true;
  }

  function getTeamName(team: UUID): string {
    const foundTeam = selectedTeams.find((t) => t.teamId.value === team.value);
    return (!!foundTeam && foundTeam.toString()) || "Unknown";
  }

  function getFormatName(format: UUID): string {
    const foundFormat = selectedFormats.find(
      (t) => t.matchFormatId.value === format.value
    );
    return (!!foundFormat && foundFormat.toString()) || "Unknown";
  }

  function getSeriesName(series: UUID): string {
    const foundSeries = selectedSeries.find(
      (t) => t.seriesId.value === series.value
    );
    return (!!foundSeries && foundSeries.toString()) || "Unknown";
  }

  function addMatchType(matchType: MatchType) {
    const settings = initialSettings;
    settings.matchTypes.push(matchType);
    update(settings);
  }

  function removeMatchType(index: number) {
    const settings = initialSettings;
    settings.matchTypes.splice(index, 1);
    update(settings);
  }

  function addTeam(team: UUID) {
    const settings = initialSettings;
    settings.teams.push(team);
    update(settings);
  }

  function removeTeam(index: number) {
    const settings = initialSettings;
    settings.teams.splice(index, 1);
    update(settings);
  }

  function addFormat(format: UUID) {
    const settings = initialSettings;
    settings.matchFormats.push(format);
    update(settings);
  }

  function removeFormat(index: number) {
    const settings = initialSettings;
    settings.matchFormats.splice(index, 1);
    update(settings);
  }

  function addSeries(series: UUID) {
    const settings = initialSettings;
    settings.series.push(series);
    update(settings);
  }

  function removeSeries(index: number) {
    const settings = initialSettings;
    settings.series.splice(index, 1);
    update(settings);
  }

  const maxPowerplayBalls = 50;

  return (
    <div
      className={
        isDefault
          ? "admin-settings-default-content"
          : "match-specific-content-component"
      }
    >
      {!isDefault && (
        <div
          className="match-specific-settings-summary"
          onClick={() => onExpand()}
        >
          <div className="admin-settings-match-specific-buttons">
            <TooltipIconButton
              title={"Rename"}
              onClick={() => setRenaming(true)}
              icon={"edit"}
              disabled={renaming}
            />
            {!renaming && name}
            {renaming && (
              <TextField
                value={name}
                onChange={(event) => {
                  setName(event.target.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    updateField("name", name);
                    setRenaming(false);
                  }
                }}
                onBlur={(event) => {
                  updateField("name", name);
                  setRenaming(false);
                }}
                variant="outlined"
                disabled={false}
              />
            )}
          </div>

          <div className="admin-settings-match-specific-buttons">
            <TimeAgo datetime={new Date(initialSettings.createdAt)} />
            <TooltipIconButton
              title={"Delete"}
              onClick={() => deleteSettings()}
              icon={"delete"}
            />
          </div>
        </div>
      )}
      {(expanded || isDefault) && (
        <div className={isDefault ? "" : "match-specific-content-expanded"}>
          {!isDefault && (
            <div className="match-specific-parameters-content">
              <div className="match-specific-settings-section-header">
                Applies To Matches
              </div>
              <hr />
              <div className="match-specific-settings-parameters">
                <div className="match-specific-settings-parameter-selector">
                  Match Types
                  <EnumSelector
                    enumObject={MatchType}
                    value={null}
                    readableValues={matchTypeNames}
                    onSelect={(matchType) => addMatchType(matchType)}
                    disabled={false}
                  />
                  <div>
                    {initialSettings.matchTypes.map((matchType, index) => (
                      <Chip
                        label={matchTypeNames[matchType]}
                        key={`already-selected-matchType-${index}`}
                        variant="outlined"
                        onDelete={() => removeMatchType(index)}
                      />
                    ))}
                  </div>
                </div>
                <div className="match-specific-settings-parameter-selector">
                  Formats
                  <EntityAutoSelector
                    value={null}
                    label=""
                    options={observableFrom(allFormats)}
                    optionService={services.matchFormatService}
                    onSelect={(entity: MatchFormat) =>
                      addFormat(entity.matchFormatId)
                    }
                    canAddNew={false}
                    maxDropdownSize={
                      MATCH_SPECIFIC_SETTINGS_DEFAULT_DROP_DOWN_SIZE
                    }
                    canEdit={false}
                  />
                  <div>
                    {initialSettings.matchFormats.map((format, index) => (
                      <Chip
                        label={getFormatName(format)}
                        key={`already-selected-format-${index}`}
                        variant="outlined"
                        onDelete={() => removeFormat(index)}
                      />
                    ))}
                  </div>
                </div>
                <div className="match-specific-settings-parameter-selector">
                  Series
                  <EntityAutoSelector
                    value={null}
                    label=""
                    options={observableFrom(allSeries)}
                    optionService={services.seriesService}
                    onSelect={(entity: Series) => addSeries(entity.seriesId)}
                    canAddNew={false}
                    maxDropdownSize={
                      MATCH_SPECIFIC_SETTINGS_DEFAULT_DROP_DOWN_SIZE
                    }
                    canEdit={false}
                  />
                  <div>
                    {initialSettings.series.map((series, index) => (
                      <Chip
                        label={getSeriesName(series)}
                        key={`already-selected-series-${index}`}
                        variant="outlined"
                        onDelete={() => removeSeries(index)}
                      />
                    ))}
                  </div>
                </div>
                <div className="match-specific-settings-parameter-selector">
                  Teams
                  <EntityAutoSelector
                    value={null}
                    label=""
                    options={observableFrom(allTeams)}
                    optionService={services.teamService}
                    onSelect={(entity: Team) => addTeam(entity.teamId)}
                    canAddNew={false}
                    canEdit={false}
                  />
                  <div>
                    {initialSettings.teams.map((team, index) => (
                      <Chip
                        label={getTeamName(team)}
                        key={`already-selected-team-${index}`}
                        variant="outlined"
                        onDelete={() => removeTeam(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="match-specific-settings-content">
            <div className="match-specific-settings-section-header">
              Settings
            </div>
            <hr />
            <div className="admin-settings-default-content">
              <div>
                <div className="admin-settings-default-content-section-header">
                  Thought Wicket Multiplier
                </div>
                <div className="admin-settings-default-content-subsection-header">
                  Innings Settings
                </div>
                <div className="thought-wicket-multiplier-settings">
                  <div className="thought-wicket-multiplier-section">
                    <div>Batting First</div>
                    <NumberSelector
                      label={"Multiplier at last ball"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={initialSettings.twmAtLastBallInnings1}
                      onValid={(valid: number) =>
                        updateField("twmAtLastBallInnings1", valid)
                      }
                    />
                    <NumberSelector
                      label={"Addition per ball"}
                      min={0}
                      max={1}
                      step={0.0001}
                      decimalPlaces={4}
                      initial={initialSettings.twmAdditionInnings1}
                      onValid={(valid: number) =>
                        updateField("twmAdditionInnings1", valid)
                      }
                    />
                  </div>
                  <div className="thought-wicket-multiplier-section">
                    <div>Batting Second</div>
                    <NumberSelector
                      label={"Multiplier at last ball"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={initialSettings.twmAtLastBallInnings2}
                      onValid={(valid: number) =>
                        updateField("twmAtLastBallInnings2", valid)
                      }
                    />
                    <NumberSelector
                      label={"Addition per ball"}
                      min={0}
                      max={1}
                      step={0.0001}
                      decimalPlaces={4}
                      initial={initialSettings.twmAdditionInnings2}
                      onValid={(valid: number) =>
                        updateField("twmAdditionInnings2", valid)
                      }
                    />
                  </div>
                </div>

                <div className="admin-settings-default-content-subsection-header">
                  Player Settings
                </div>
                <div className="thought-wicket-multiplier-settings">
                  <NumberSelector
                    label={"Balls until set"}
                    min={0}
                    max={1000}
                    step={1}
                    decimalPlaces={0}
                    initial={initialSettings.playerTwmBallsUntilSet}
                    onValid={(valid: number) =>
                      updateField("playerTwmBallsUntilSet", valid)
                    }
                  />
                  <NumberSelector
                    label={"Multiplier at first ball"}
                    min={0}
                    max={100}
                    step={0.01}
                    decimalPlaces={2}
                    initial={initialSettings.playerTwmFirstBallMultiplier}
                    onValid={(valid: number) =>
                      updateField("playerTwmFirstBallMultiplier", valid)
                    }
                  />
                  <NumberSelector
                    label={"Divisor"}
                    min={0}
                    max={1000}
                    step={1}
                    decimalPlaces={0}
                    initial={initialSettings.playerTwmDivisor}
                    onValid={(valid: number) =>
                      updateField("playerTwmDivisor", valid)
                    }
                  />
                  <NumberSelector
                    label={"Multiplier at other balls"}
                    min={0}
                    max={100}
                    step={0.01}
                    decimalPlaces={2}
                    initial={initialSettings.playerTwmOtherBallMultiplier}
                    onValid={(valid: number) =>
                      updateField("playerTwmOtherBallMultiplier", valid)
                    }
                  />
                </div>
              </div>
              <hr />
              <div>
                <div className="admin-settings-default-content-section-header">
                  Bowling Type by Phase Multipliers
                </div>
                {bowlingTypeByPhaseMultipliersConfig.map((config) => (
                  <BowlingTypeByPhaseMultipliersComponent
                    value={initialSettings[config.property]}
                    title={config.title}
                    onChange={(updatedMultipliers: number[][]) =>
                      updateField(config.property, updatedMultipliers)
                    }
                    key={config.property}
                  />
                ))}
              </div>
              <hr />
              <div>
                <div className="admin-settings-default-content-section-header">
                  Second Innings Push Adjust
                </div>
                <div className="second-innings-push-adjust-settings">
                  <NumberSelector
                    label={"Adjustment"}
                    min={0}
                    max={1}
                    step={0.01}
                    decimalPlaces={2}
                    initial={initialSettings.secondInningsPushAdjustment}
                    onValid={(valid: number) =>
                      updateField("secondInningsPushAdjustment", valid)
                    }
                  />
                  <NumberSelector
                    label={"Dilution"}
                    min={0}
                    max={1}
                    step={0.01}
                    decimalPlaces={2}
                    initial={initialSettings.secondInningsPushDilution}
                    onValid={(valid: number) =>
                      updateField("secondInningsPushDilution", valid)
                    }
                  />
                </div>
              </div>
              <hr />
              <div>
                <div className="admin-settings-default-content-section-header">
                  Powerplay Settings
                </div>

                <PowerplaySettingsComponent
                  powerplayAdjustments={initialSettings.powerplayAdjustments}
                  ballsInPowerplay={maxPowerplayBalls}
                  onUpdate={(updatedAdjustments: PowerplayAdjustments) =>
                    updatePowerplayAdjustments(updatedAdjustments)
                  }
                />
              </div>
              <hr />
              <div>
                <div className="admin-settings-default-content-section-header">
                  Surge Settings
                </div>
                <div className="thought-wicket-multiplier-settings">
                  <div className="thought-wicket-multiplier-section">
                    Innings 1
                    <NumberSelector
                      label={"Strike Rate Bias"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={4}
                      initial={initialSettings.surgeStrikeRateBiasInnings1}
                      onValid={(valid: number) =>
                        updateField("surgeStrikeRateBiasInnings1", valid)
                      }
                    />
                    <NumberSelector
                      label={"Wicket % Bias"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={4}
                      initial={initialSettings.surgeWicketBiasInnings1}
                      onValid={(valid: number) =>
                        updateField("surgeWicketBiasInnings1", valid)
                      }
                    />
                  </div>
                  <div className="thought-wicket-multiplier-section">
                    Innings 2
                    <NumberSelector
                      label={"Strike Rate Bias"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={4}
                      initial={initialSettings.surgeStrikeRateBiasInnings2}
                      onValid={(valid: number) =>
                        updateField("surgeStrikeRateBiasInnings2", valid)
                      }
                    />
                    <NumberSelector
                      label={"Wicket % Bias"}
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={4}
                      initial={initialSettings.surgeWicketBiasInnings2}
                      onValid={(valid: number) =>
                        updateField("surgeWicketBiasInnings2", valid)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            {initialSettings.teams.length === 0 && (
              <div>
                <hr />
                <div className="admin-settings-default-content-section-header">
                  Confidence Limits
                </div>
                <ConfidenceSettingTable
                  adminConfidences={initialSettings.adminConfidences}
                  onUpdate={(type, field, value) =>
                    updateConfidences(type, field, value)
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
