import { Button, CircularProgress, Switch, Tooltip } from "@mui/material";
import { Component } from "react";

import { Subscription } from "rxjs";
import { KeycloakUser } from "../../services/keycloak-service";
import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { AdminPreferences } from "../../types/preferences/admin-preferences";
import {
  SimulatorScenario,
  UserPreferences,
} from "../../types/preferences/preferences";
import { SimulatorRoute, UserRoute } from "../../types/route-helpers";
import { services, showConfirmationDialog } from "../../types/services";
import { NodeHealth } from "../../types/simulator/node-health";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import AppAlert from "../common-components/app-alert";
import RunSimulatorButton from "../common-components/run-simulator-button";
import { noHealthyNodes, noSimulationsAllowed } from "../component-utils";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import { StatsLoadingPage } from "../stats-loading-page/stats-loading-page";

import ClearBatsmanPushDisplay from "./clear-batsman-push-display";
import { EditSquadDisplay } from "./edit-squad-display";
import { FormSettingsModal } from "./form-settings-modal";
import { batchUpdateAll } from "./squad-page-utils";
import { StealAllPage } from "./steal-all-page";

interface State {
  match: Match;
  team1: Team;
  team2: Team;
  squad1: Squad;
  squad2: Squad;
  gameState: GameState;
  matchFormat: MatchFormat;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  generatingAll: boolean;
  rescraping: boolean;
  playerStats: Map<string, PlayerStatsWrapper>;
  comparedPlayerStats: Map<string, PlayerStatsWrapper>;
  teamMatchStats: Map<string, MatchStatsWrapper>;
  formSettingsModalOpen: boolean;
  fromThisPoint: boolean;
  comparedUserName: string;
  comparedUserId: string;
  userPreferences: UserPreferences;
  nodeHealth: NodeHealth[];
  latestVersion: string;
  adminPreferences: AdminPreferences;
  matchStats: MatchStatsWrapper;
}

export class SquadPage extends Component<{}, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    match: null,
    gameState: null,
    matchFormat: null,
    team1: null,
    team2: null,
    squad1: null,
    squad2: null,
    simulationResult: null,
    comparedResult: null,
    comparedUserId: null,
    generatingAll: false,
    rescraping: false,
    playerStats: null,
    comparedPlayerStats: null,
    formSettingsModalOpen: false,
    fromThisPoint: true,
    comparedUserName: null,
    userPreferences: null,
    nodeHealth: null,
    latestVersion: null,
    adminPreferences: null,
    teamMatchStats: new Map(),
    matchStats: null,
  };

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      ...SquadPage.DEFAULT_STATE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.currentGameService.currentMatchSubject.subscribe(
        (currentMatch: Match) => this.setState({ match: currentMatch })
      )
    );

    this.subscriptions.push(
      services.currentGameService.squadsSubject.subscribe(
        ([squad1, squad2]: Squad[]) => this.setState({ squad1, squad2 })
      )
    );

    this.subscriptions.push(
      services.currentGameService.teamsSubject.subscribe(
        ([team1, team2]: Team[]) => this.setState({ team1, team2 })
      )
    );

    this.subscriptions.push(
      services.playerStatsService.playerStatsSubject.subscribe(
        (playerStats: Map<string, PlayerStatsWrapper>) =>
          this.setState({ playerStats })
      )
    );

    this.subscriptions.push(
      services.playerStatsService.comparedUserStatsSubject.subscribe(
        (comparedPlayerStats: Map<string, PlayerStatsWrapper>) =>
          this.setState({ comparedPlayerStats })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(
        (currentState: GameState) => this.setState({ gameState: currentState })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentMatchFormatSubject.subscribe(
        (matchFormat: MatchFormat) => this.setState({ matchFormat })
      )
    );

    this.subscriptions.push(
      services.userService.adminPreferencesSubject.subscribe(
        (adminPreferences: AdminPreferences) =>
          this.setState({ adminPreferences })
      )
    );

    this.subscriptions.push(
      services.simulationService.latestTruePriceSimulationResultSubject.subscribe(
        (scenarioResults: [SimulatorScenario, SimulationResult]) => {
          if (!!scenarioResults) {
            this.setState({ simulationResult: scenarioResults[1] });
          } else {
            this.setState({ simulationResult: null });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.comparedUserResultsSubject.subscribe(
        (
          comparedResults: Map<string, [SimulatorScenario, SimulationResult]>
        ) => {
          if (!!comparedResults && !!comparedResults.get("default")) {
            this.setState({
              comparedResult: comparedResults.get("default")[1],
            });
          } else {
            this.setState({ comparedResult: null });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeHealthSubject.subscribe(
        (nodes: NodeHealth[]) => {
          if (nodes) {
            this.setState({
              nodeHealth: nodes,
            });
          }
        }
      )
    );

    this.subscriptions.push(
      services.simulationService.latestNodeVersionSubject.subscribe(
        (latestVersion: string) => {
          this.setState({ latestVersion: latestVersion });
        }
      )
    );

    this.subscriptions.push(
      services.keycloakService.comparedUserSubject.subscribe(
        (comparedUser: KeycloakUser) =>
          this.setState({
            comparedUserId: comparedUser ? comparedUser.id : null,
            comparedUserName: comparedUser ? comparedUser.name : null,
          })
      )
    );

    this.subscriptions.push(
      services.userService.userPreferencesSubject.subscribe((userPreferences) =>
        this.setState({ userPreferences })
      )
    );

    this.subscriptions.push(
      services.matchStatsService.teamMatchStatsSubject.subscribe(
        (teamMatchStats) => this.setState({ teamMatchStats })
      )
    );

    this.subscriptions.push(
      services.matchStatsService.matchStatsSubject.subscribe(
        (matchStats: MatchStatsWrapper) =>
          this.setState({ matchStats: matchStats })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private refreshSim() {
    services.simulationService.removeSimulationResults();
    services.simulationService.simulate();
  }

  private stealAllHandler(): void {
    batchUpdateAll(
      this.state.playerStats,
      this.state.comparedPlayerStats,
      PlayerStatsWrapper.getAllProperties(
        StealAllPage.PLAYER_BATTING_STATS,
        StealAllPage.PLAYER_BOWLING_STATS,
        StealAllPage.BOWLING_ALGORITHM_MODAL
      ),
      (updatedStats: Map<string, PlayerStatsWrapper>) => {
        services.playerStatsService.updateMultiplePlayerStats(
          updatedStats,
          this.state.match.matchId
        );
      }
    );
  }

  private updatePreferences(settings: UserPreferences) {
    services.userService
      .updateUserPreferences(settings)
      .then(() => this.setState({ formSettingsModalOpen: false }));
  }

  public render() {
    const comparedTraderConfidence = this.state.teamMatchStats?.get(
      this.state.comparedUserId
    )?.matchStats.traderConfidence;

    return (
      <UserRoute>
        <div className="full-push-background-light with-navbar">
          {noSimulationsAllowed(
            this.state.match,
            this.state.userPreferences
          ) && (
            <AppAlert>
              Check if you have at least 1 simulation enabled in settings and
              that the simulator is enabled.
            </AppAlert>
          )}
          {noHealthyNodes(this.state.nodeHealth, this.state.latestVersion) && (
            <AppAlert severity="warning">
              You have no nodes, please set them up to run the simulator
            </AppAlert>
          )}
          <div className="page-title-and-buttons">
            <div className="page-title">Player Settings</div>
            {this.state.match && this.state.match.matchId && (
              <div className="squad-buttons">
                {this.state.gameState &&
                  this.state.match &&
                  !this.state.gameState.started &&
                  this.state.match.espnNumNonUnique !== null && (
                    <Button
                      onClick={() =>
                        this.setState({ rescraping: true }, () =>
                          services.matchService
                            .rescrapePlayers(this.state.gameState.matchId)
                            .finally(() => this.setState({ rescraping: false }))
                        )
                      }
                      variant="contained"
                    >
                      Rescrape Squads
                    </Button>
                  )}
                <SimulatorRoute>
                  <ClearBatsmanPushDisplay
                    playerStats={this.state.playerStats}
                    matchId={this.state.match.matchId}
                    onProceed={(updatedStats) => {
                      services.playerStatsService.updateMultiplePlayerStats(
                        updatedStats,
                        this.state.match.matchId
                      );
                      this.setState({ playerStats: updatedStats });
                    }}
                  />
                  <ComparedUserSelector />
                  {/* ComparedStats comes in as an empty map when there's no comparison */}
                  {this.state.comparedPlayerStats.size > 0 && (
                    <StealStatsButton
                      comparedUserName={this.state.comparedUserName}
                      disabled={areStatsEqual(
                        this.state.playerStats,
                        this.state.comparedPlayerStats,
                        PlayerStatsWrapper.getAllProperties(
                          StealAllPage.PLAYER_BATTING_STATS,
                          StealAllPage.PLAYER_BOWLING_STATS,
                          StealAllPage.BOWLING_ALGORITHM_MODAL
                        ),
                        "playerStats"
                      )}
                      tooltipMessage={getToolTipMessage(
                        this.state.playerStats,
                        this.state.comparedPlayerStats,
                        PlayerStatsWrapper.getAllProperties(
                          StealAllPage.PLAYER_BATTING_STATS,
                          StealAllPage.PLAYER_BOWLING_STATS,
                          StealAllPage.BOWLING_ALGORITHM_MODAL
                        ),
                        this.state.comparedUserName,
                        "playerStats"
                      )}
                      onClickHandler={this.stealAllHandler.bind(this)}
                    />
                  )}
                  <Button
                    onClick={() =>
                      showConfirmationDialog(
                        () => {
                          this.setState({ generatingAll: true }, () =>
                            services.playerStatsService
                              .generateAllPlayerStats(this.state.match.matchId)
                              .then(() => {
                                this.setState({ generatingAll: false });
                              })
                          );
                        },
                        "Regenerate All Player Stats",
                        "Are you sure?"
                      )
                    }
                    variant={"contained"}
                    color="secondary"
                  >
                    Regenerate All Player Stats
                  </Button>
                  <Button
                    onClick={() =>
                      showConfirmationDialog(
                        () =>
                          services.playerStatsService.defaultAllPlayerStats(
                            this.state.match.matchId
                          ),
                        "Default All Player Stats",
                        "Are you sure?"
                      )
                    }
                    variant={"contained"}
                    color="primary"
                  >
                    Default All Player Stats
                  </Button>
                  <Tooltip
                    title={
                      this.state.fromThisPoint
                        ? "Stats displayed from this point"
                        : "Stats displayed for whole match"
                    }
                  >
                    <Switch
                      checked={this.state.fromThisPoint}
                      onChange={(event, checked) =>
                        this.setState({ fromThisPoint: checked })
                      }
                    />
                  </Tooltip>
                  {this.state.gameState &&
                    this.state.gameState.initialStatsLoaded && (
                      <RunSimulatorButton
                        isDisabled={noSimulationsAllowed(
                          this.state.match,
                          this.state.userPreferences
                        )}
                        onClick={() => this.refreshSim()}
                      />
                    )}
                  <TooltipIconButton
                    title="Form Calculation Settings"
                    disabled={false}
                    onClick={() =>
                      this.setState({ formSettingsModalOpen: true })
                    }
                    icon="settings"
                    colour="#f8f8ff"
                  />
                </SimulatorRoute>
              </div>
            )}
          </div>
          {(this.state.generatingAll ||
            !this.state.playerStats ||
            this.state.rescraping) && (
            <div className="squad-page-regenerating">
              <CircularProgress />
              Loading Player Stats..
            </div>
          )}
          {!this.state.generatingAll &&
            this.state.playerStats &&
            !this.state.rescraping && (
              <div className="squad-page">
                {this.state.gameState &&
                  this.state.gameState.initialStatsLoaded && (
                    <div className="squad-display">
                      {this.state.squad1 && this.state.team1 && (
                        <EditSquadDisplay
                          teamNumber={1}
                          matchFormat={this.state.matchFormat}
                          gameState={this.state.gameState}
                          team={this.state.team1}
                          match={this.state.match}
                          squad={this.state.squad1}
                          squad1={this.state.squad1}
                          squad2={this.state.squad2}
                          team1={this.state.team1}
                          team2={this.state.team2}
                          simulationResult={this.state.simulationResult}
                          comparedResult={this.state.comparedResult}
                          playerStats={this.state.playerStats}
                          onSquadUpdate={() =>
                            services.currentGameService.updateTeams()
                          }
                          fromThisPoint={this.state.fromThisPoint}
                          comparedUserName={this.state.comparedUserName}
                          userPreferences={this.state.userPreferences}
                          adminPreferences={this.state.adminPreferences}
                          comparedTraderConfidence={comparedTraderConfidence}
                          matchStats={this.state.matchStats}
                        />
                      )}
                      {this.state.squad2 && this.state.team2 && (
                        <EditSquadDisplay
                          teamNumber={2}
                          matchFormat={this.state.matchFormat}
                          gameState={this.state.gameState}
                          team={this.state.team2}
                          match={this.state.match}
                          squad={this.state.squad2}
                          squad1={this.state.squad1}
                          squad2={this.state.squad2}
                          team1={this.state.team1}
                          team2={this.state.team2}
                          simulationResult={this.state.simulationResult}
                          comparedResult={this.state.comparedResult}
                          playerStats={this.state.playerStats}
                          onSquadUpdate={() =>
                            services.currentGameService.updateTeams()
                          }
                          fromThisPoint={this.state.fromThisPoint}
                          comparedUserName={this.state.comparedUserName}
                          userPreferences={this.state.userPreferences}
                          adminPreferences={this.state.adminPreferences}
                          comparedTraderConfidence={comparedTraderConfidence}
                          matchStats={this.state.matchStats}
                        />
                      )}
                    </div>
                  )}
                {this.state.gameState &&
                  !this.state.gameState.initialStatsLoaded && (
                    <StatsLoadingPage />
                  )}
                {!this.state.gameState && (
                  <div className="page-title">No match selected</div>
                )}
              </div>
            )}

          <FormSettingsModal
            open={this.state.formSettingsModalOpen}
            onCancel={() => {
              this.setState({ formSettingsModalOpen: false });
            }}
            onProceed={(settings) => this.updatePreferences(settings)}
          />
        </div>
      </UserRoute>
    );
  }
}
