import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { Player } from "../../../types/entities/player";
import { Squad } from "../../../types/entities/squad";
import { SimulationResult } from "../../../types/simulator/simulation-result";
import { PlayerStatsWrapper } from "../../../types/stats/player-stats";
import { getAverageBallsBowled } from "../../simulator-page/simulator-utils";

export function getPlayerTeamId(
  squad1: Squad,
  squad2: Squad,
  player: Player
): string {
  if (!squad1.players || !squad2.players) {
    return "";
  }

  return squad1.players.some((p) => p.playerId.value === player.playerId.value)
    ? squad1.teamId.value
    : squad2.teamId.value;
}

export function getRelevantSquads(
  squad1: Squad,
  squad2: Squad,
  gameState: GameState,
  matchFormat: MatchFormat
): Squad[] {
  if (!squad1 || !squad2) {
    return;
  }

  if (!gameState.started) {
    return [squad1, squad2];
  }

  const bowlingTeamId = gameState.getTeamId(
    gameState.getBowlingTeamForInnings(gameState.innings, matchFormat)
  );

  return [squad1.teamId.value === bowlingTeamId.value ? squad1 : squad2];
}

export function getPlayers(
  squad1: Squad,
  squad2: Squad,
  playerStats: Map<string, PlayerStatsWrapper>,
  gameState: GameState,
  simulationResult: SimulationResult,
  matchFormat: MatchFormat
): {
  players: Array<{
    player: Player;
    playerStatsWrapper: PlayerStatsWrapper;
    ballsBowledAvg: number;
    teamNumber: number;
    playerTeamId: string;
  }>;
  totalBallsBowledAvg: number;
} {
  const squads = getRelevantSquads(squad1, squad2, gameState, matchFormat);
  if (!squads) {
    return { players: [], totalBallsBowledAvg: 0 };
  }

  let totalBallsBowledAvg = 0;

  const players = squads
    .flatMap((squad) =>
      squad.players.map((p) => {
        return {
          player: p,
          teamNumber: squad === squad1 ? 1 : 2,
          playerTeamId: squad.teamId.value,
        };
      })
    )
    .map(({ player, teamNumber, playerTeamId }) => {
      const playerStatsWrapper = playerStats?.get(player.playerId.value);
      const ballsBowledAvg = Number(
        getAverageBallsBowled(player.playerId, teamNumber, simulationResult)
      );

      totalBallsBowledAvg += ballsBowledAvg;

      return {
        player,
        playerStatsWrapper,
        ballsBowledAvg,
        teamNumber,
        playerTeamId,
      };
    })
    .filter(
      ({ playerStatsWrapper }) =>
        !gameState.started || playerStatsWrapper?.playerStats?.bowler
    );

  return {
    players,
    totalBallsBowledAvg,
  };
}
