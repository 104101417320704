import { PowerplayAdjustments } from "../../types/preferences/admin-preferences";
import { infinitySafeWithDefault } from "../../types/util-functions";

export function calculatePowerplayAdjustments(
  adjustments: PowerplayAdjustments,
  ballsInPowerplay: number
): number[][] {
  let swingAmount = adjustments.swingUpToBall > 0 ? adjustments.swingPower : 0;
  const result = [[], [], []];
  for (let i = 0; i < ballsInPowerplay; i++) {
    const swingAmountSquared = Math.pow(swingAmount, adjustments.swingExponent);
    const srBias =
      adjustments.baseSrBias -
      (swingAmountSquared / 100) * adjustments.srMultiplier;
    const wicketBias =
      adjustments.baseWicketBias +
      (swingAmountSquared / 100) * adjustments.wicketMultiplier;
    const pushAdjustment = adjustments.basePushAdjustment - swingAmountSquared;

    result[0].push(infinitySafeWithDefault(srBias, adjustments.baseSrBias));
    result[1].push(
      infinitySafeWithDefault(wicketBias, adjustments.baseWicketBias)
    );
    result[2].push(
      infinitySafeWithDefault(pushAdjustment, adjustments.basePushAdjustment)
    );

    swingAmount = Math.max(
      0,
      adjustments.swingPower -
        ((i + 1) / adjustments.swingUpToBall) * adjustments.swingPower
    );
  }
  return result;
}
