import { Player } from "../../types/entities/player";
import { TeamPlayer } from "../../types/entities/team-player";
import { humanReadableMatchRoles } from "../../types/enums/match-role";
import { PlayerView } from "../my-matches/match-creation-modals/player-view";

interface Props {
  player: Player;
  teamPlayer: TeamPlayer;
}

export default function PlayerTableRowPlayerData({
  player,
  teamPlayer,
}: Readonly<Props>): React.JSX.Element {
  return (
    <div className="player-stats-info">
      <PlayerView
        player={player}
        canEdit={true}
        canDelete={false}
        showName={false}
        imgWidth={88}
        imgHeight={110}
        imgClasses={"scorecard-image"}
        width={"80"}
      />
      <div className="player-stats-name-and-role">
        <div>{player.longName}</div>
        <div className="italic">
          {teamPlayer.matchRole !== "PLAYER"
            ? humanReadableMatchRoles[teamPlayer.matchRole]
            : ""}
        </div>
      </div>
    </div>
  );
}
