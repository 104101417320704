import { ReactComponent as GreyBallSVG } from "../../img/events/bowler-not-selected.svg";
import { ReactComponent as BallSVG } from "../../img/events/bowler-selected.svg";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { TeamPlayer } from "../../types/entities/team-player";
import { Direction } from "../../types/enums/direction";
import { SimulatorRoute } from "../../types/route-helpers";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { UUID } from "../../types/uuid";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import OversReservationButtons from "../stats-editing-components/overs-reservation-buttons";

interface Props {
  gameState: GameState;
  player: Player;
  teamPlayer: TeamPlayer;
  order: number;
  team: Team;
  squad: Squad;
  playerStats: PlayerStatsWrapper;
  matchFormat: MatchFormat;
  matchStats: MatchStatsWrapper;
  teamNumber: number;
  movePlayer: (playerId: UUID, direction: Direction, steps: number) => void;
  removePlayer: (playerId: UUID, teamId: UUID) => void;
  toggleBowler: (playerStats: PlayerStatsWrapper) => void;
  showStatsModal: (order: number) => void;
  showInningsProgressionModal: (order: number) => void;
}

export default function PlayerTableRowButtons({
  gameState,
  player,
  teamPlayer,
  order,
  team,
  squad,
  playerStats,
  matchFormat,
  matchStats,
  teamNumber,
  movePlayer,
  removePlayer,
  toggleBowler,
  showStatsModal,
  showInningsProgressionModal,
}: Readonly<Props>) {
  return (
    <div className="player-stats-button-container">
      <div className="player-stats-buttons">
        <div>
          <TooltipIconButton
            title="Move Up"
            disabled={teamPlayer.battingOrder === 0}
            onClick={() => movePlayer(player.playerId, Direction.UP, 1)}
            icon="keyboard_arrow_up"
          />
          <TooltipIconButton
            title="Move Down"
            disabled={teamPlayer.battingOrder >= squad.players.length - 1}
            onClick={() => movePlayer(player.playerId, Direction.DOWN, 1)}
            icon="keyboard_arrow_down"
          />
        </div>

        <SimulatorRoute>
          <TooltipIconButton
            title="Stats"
            onClick={() => showStatsModal(order)}
            icon="assignment"
          />
          <TooltipIconButton
            title="Innings progression"
            onClick={() => showInningsProgressionModal(order)}
            icon="ssid_chart"
          />
        </SimulatorRoute>
        <SimulatorRoute>
          <TooltipIconButton
            title={
              playerStats.playerStats.bowler ? "Block bowler" : "Unblock bowler"
            }
            onClick={() => toggleBowler(playerStats)}
            icon={
              playerStats.playerStats.bowler ? (
                <BallSVG className="tooltip-icon-button" />
              ) : (
                <GreyBallSVG className="tooltip-icon-button" />
              )
            }
          />
        </SimulatorRoute>
        {!!gameState && !gameState.started && (
          <TooltipIconButton
            title="Remove Player"
            onClick={() => removePlayer(player.playerId, team.teamId)}
            icon="cancel"
          />
        )}
      </div>
      {gameState && (
        <OversReservationButtons
          playerId={player.playerId}
          gameState={gameState}
          matchFormat={matchFormat}
          matchStats={matchStats}
          teamNumber={teamNumber}
        />
      )}
    </div>
  );
}
