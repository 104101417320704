import { Button, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { KeycloakUser } from "../../services/keycloak-service";
import {
  PotentialSharedMatch,
  ShareStatus,
} from "../../types/entities/potential-shared-match";
import { services } from "../../types/services";

interface Props {
  potentialSharedMatch: PotentialSharedMatch;
  extraNotificationsLength: number;
}

export default function PotentialSharedMatchNotificationPopup({
  potentialSharedMatch,
  extraNotificationsLength,
}: Readonly<Props>): React.JSX.Element {
  const [users, setUsers] = useState<KeycloakUser[]>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.keycloakService.knownUsersSubject.subscribe(
        (users: KeycloakUser[]) => setUsers(users)
      ),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [potentialSharedMatch]);

  const sharedBy: KeycloakUser = users?.find(
    (u) => u?.id === potentialSharedMatch?.createdBy
  );

  return (
    <div className="potential-shared-match-container">
      <div className="potential-shared-match-popup">
        <div className="potential-shared-match-popup-text">
          <div>{sharedBy?.name || "Someone"} shared a match with you</div>
          <div className="italic">{potentialSharedMatch.description}</div>
        </div>
        <div className="potential-shared-match-popup-buttons">
          {potentialSharedMatch.shareStatus === ShareStatus.POTENTIAL && (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setLoading(true);
                  services.matchService.acceptPotentialSharedMatch(
                    potentialSharedMatch.potentialSharedMatchId
                  );
                }}
                disabled={loading}
              >
                Accept
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setLoading(true);
                  services.matchService.declinePotentialSharedMatch(
                    potentialSharedMatch.potentialSharedMatchId
                  );
                }}
                disabled={loading}
              >
                Decline
              </Button>
            </>
          )}
          {potentialSharedMatch.shareStatus === ShareStatus.ACCEPTED && (
            <CircularProgress />
          )}
        </div>
      </div>
      {extraNotificationsLength !== 0 && (
        <div className="potential-shared-match-popup-list">
          <div className="potential-shared-match-popup-collapsed"></div>
          {extraNotificationsLength} more..
        </div>
      )}
    </div>
  );
}
