export enum BetType {
  MATCH_ODDS = "MATCH_ODDS",
  FALL_OF_WICKET = "FALL_OF_WICKET",
  SESSION = "SESSION",
  INNINGS_RUNS = "INNINGS_RUNS",
  BATSMAN_RUNS = "BATSMAN_RUNS",
  BATSMAN_BOUNDARIES = "BATSMAN_BOUNDARIES",
  BATSMAN_BALLS_FACED = "BATSMAN_BALLS_FACED",
  MATCH_RUNS = "MATCH_RUNS",
  TIED_MATCH = "TIED_MATCH",
  TOP_BAT = "TOP_BAT",
  SUPER_OVER = "SUPER_OVER",
  COMPLETED_MATCH = "COMPLETED_MATCH",
  UNKNOWN = "UNKNOWN",
}

export const betTypeReadableValues: { [key in BetType]: string } = {
  [BetType.MATCH_ODDS]: "Match Odds",
  [BetType.FALL_OF_WICKET]: "Fall of Wicket",
  [BetType.SESSION]: "Session",
  [BetType.INNINGS_RUNS]: "Innings Runs",
  [BetType.BATSMAN_RUNS]: "Batsman Runs",
  [BetType.BATSMAN_BOUNDARIES]: "Batsman Boundaries",
  [BetType.BATSMAN_BALLS_FACED]: "Batsman Balls Faced",
  [BetType.MATCH_RUNS]: "Match Runs",
  [BetType.TIED_MATCH]: "Tied Match",
  [BetType.TOP_BAT]: "Top Bat",
  [BetType.SUPER_OVER]: "Super Over",
  [BetType.COMPLETED_MATCH]: "Completed Match",
  [BetType.UNKNOWN]: "Unknown",
};
