import { useState } from "react";
import { MatchFormat } from "../../types/entities/match-format";
import {
  PowerplayAdjustmentField,
  PowerplayAdjustments,
} from "../../types/preferences/admin-preferences";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import PercentDistributionComparison from "../stats-editing-components/percent-distribution-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import StealStatsHeadingAndButton from "../stats-editing-components/steal-stats-heading-and-button";
import PowerplaySettingsComponent from "./powerplay-settings-component";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  matchFormat: MatchFormat;
  onUpdate: (updatedStats: MatchStatsWrapper) => void;
}

interface PowerplayInningsConfig {
  property: PowerplayAdjustmentField;
  name: string;
  min: number;
  max: number;
  dp: number;
  step: number;
}

type PowerplayAdjustmentsType =
  | "powerplayAdjustmentsInnings1"
  | "powerplayAdjustmentsInnings2";

export default function MatchStatsPowerplaySettingsComponent({
  currentStats,
  comparedStats,
  comparedUserName,
  matchFormat,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const [selectedInnings, setSelectedInnings] = useState<number>(1);

  const onSteal = (updates: string[]) => {
    updates.forEach((update) => {
      updateStats(update, comparedStats.matchStats[update]);
    });
  };

  const updateStats = (property: string, value) => {
    const updatedStats: MatchStatsWrapper = currentStats;
    updatedStats.matchStats[property] = value;
    onUpdate(updatedStats);
  };

  const baseConfig: PowerplayInningsConfig[] = [
    {
      property: "basePushAdjustment",
      name: "Base Push Adjustment",
      min: -100,
      max: 100,
      dp: 0,
      step: 1,
    },
    {
      property: "baseWicketBias",
      name: "Base Wicket Bias",
      min: 0,
      max: 10,
      dp: 2,
      step: 0.01,
    },
    {
      property: "baseSrBias",
      name: "Base Strike Rate Bias",
      min: 0,
      max: 10,
      dp: 2,
      step: 0.01,
    },
  ];

  const swingConfig: PowerplayInningsConfig[] = [
    {
      property: "swingUpToBall",
      name: "Swing up to ball",
      min: 0,
      max: 1000,
      dp: 0,
      step: 1,
    },
    {
      property: "swingPower",
      name: "Swing Power",
      min: 0,
      max: 5,
      dp: 2,
      step: 0.01,
    },
    {
      property: "srMultiplier",
      name: "Swing Strike Rate Penalty",
      min: 0,
      max: 5,
      dp: 1,
      step: 0.1,
    },
    {
      property: "wicketMultiplier",
      name: "Swing Wicket Penalty",
      min: 0,
      max: 5,
      dp: 1,
      step: 0.1,
    },
    {
      property: "swingExponent",
      name: "Curve Steepness",
      min: 0,
      max: 10,
      dp: 1,
      step: 0.1,
    },
  ];

  const type: PowerplayAdjustmentsType =
    selectedInnings === 1
      ? "powerplayAdjustmentsInnings1"
      : "powerplayAdjustmentsInnings2";

  return (
    <div className="match-stats-powerplay-settings-container" key={type}>
      <div className="match-stats-powerplay-settings-innings-buttons">
        <TooltipIconButton
          title={"Innings 1"}
          icon={"arrow_back_ios"}
          onClick={() => setSelectedInnings(1)}
          disabled={selectedInnings === 1}
        />
        <div className="match-stats-subtitle">Innings {selectedInnings}</div>
        <TooltipIconButton
          title={"Innings 2"}
          icon={"arrow_forward_ios"}
          onClick={() => setSelectedInnings(2)}
          disabled={selectedInnings === 2}
        />
      </div>

      <PowerplaySettingsComponent
        powerplayAdjustments={currentStats.matchStats[type]}
        ballsInPowerplay={MatchFormat.getBallsInPowerplay(
          matchFormat,
          selectedInnings
        )}
        onUpdate={(updatedAdjustments: PowerplayAdjustments) =>
          updateStats(type, updatedAdjustments)
        }
      />

      {comparedStats && (
        <div className="comparison">
          <StealStatsHeadingAndButton comparedUserName={comparedUserName}>
            <StealStatsButton
              comparedUserName={comparedUserName}
              disabled={areStatsEqual(
                currentStats,
                comparedStats,
                [type],
                "matchStats"
              )}
              tooltipMessage={getToolTipMessage(
                currentStats,
                comparedStats,
                [type],
                comparedUserName,
                "matchStats"
              )}
              onClickHandler={() => onSteal([type])}
            />
          </StealStatsHeadingAndButton>
          {baseConfig.map((adjustment) => (
            <NumberComparison
              key={adjustment.property}
              label={adjustment.name}
              originalValue={currentStats.matchStats[type][adjustment.property]}
              comparedValue={
                comparedStats.matchStats[type][adjustment.property]
              }
            />
          ))}
          {swingConfig.map((adjustment) => (
            <NumberComparison
              key={adjustment.property}
              label={adjustment.name}
              originalValue={currentStats.matchStats[type][adjustment.property]}
              comparedValue={
                comparedStats.matchStats[type][adjustment.property]
              }
            />
          ))}
          <PercentDistributionComparison
            originalValue={currentStats.matchStats[type].powerplayPercentBiases}
            comparedValue={
              comparedStats.matchStats[type].powerplayPercentBiases
            }
          />
        </div>
      )}
    </div>
  );
}
