import { ReplaySubject, Subject } from "rxjs";

import { getHomepageRoute } from "../../components/component-utils";
import {
  CricketEvent,
  deserializeCricketEventList,
} from "../../types/entities/cricket-event";
import { EntityBuilder } from "../../types/entities/entity";
import { GameState } from "../../types/entities/game-state";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { MatchInfo } from "../../types/entities/match-info";
import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { BetfairSubscription } from "../../types/enums/betfair-status";
import { DecisionType } from "../../types/enums/decision-type";
import { Direction } from "../../types/enums/direction";
import { TossChoice } from "../../types/enums/toss-choice";
import { services, showMessage } from "../../types/services";
import { UUID } from "../../types/uuid";
import { BetfairService } from "../betfair-service";
import { HttpService } from "../http-service";
import { EntityService } from "./entity-service";
import { MatchService } from "./match-service";

export class CurrentGameService extends EntityService<GameState> {
  public currentMatchId: UUID = null;
  public currentState: GameState = null;
  public currentMatch: Match = null;
  public currentMatchFormat: MatchFormat = null;
  public currentMatchInfo: MatchInfo = null;
  public shareSubject: Subject<UUID> = new ReplaySubject(1);
  public currentStateSubject: Subject<GameState> = new ReplaySubject(1);
  public currentInfoSubject: Subject<MatchInfo> = new ReplaySubject(1);
  public currentMatchSubject: Subject<Match> = new ReplaySubject(1);
  public currentMatchFormatSubject: Subject<MatchFormat> = new ReplaySubject(1);
  public latestEvents: CricketEvent[] = null;
  public latestEventsSubject: Subject<CricketEvent[]> = new ReplaySubject(1);
  public squads: Squad[] = null;
  public squadsSubject: Subject<Squad[]> = new ReplaySubject(1);
  public teams: Team[] = null;
  public teamsSubject: Subject<Team[]> = new ReplaySubject(1);
  public ground: Ground = null;
  public groundSubject: Subject<Ground> = new ReplaySubject(1);
  public currentSubscriptionSubject: Subject<BetfairSubscription> =
    new ReplaySubject(1);

  constructor(
    classToCreate: EntityBuilder<GameState>,
    httpService: HttpService,
    controllerName: string,
    matchService: MatchService
  ) {
    super(classToCreate, httpService, controllerName, false);

    matchService.getMatchIdSubject().subscribe((matchId: UUID) => {
      if (
        !matchId ||
        !this.currentMatchId ||
        this.currentMatchId.value !== matchId.value
      ) {
        services.simulationService.latestSimulationResultSubject.next(null);
        services.simulationService.latestTruePriceSimulationResultSubject.next(
          null
        );
        services.simulationService.removeDefaultSimulationResults();
        services.simulationService.comparedUserResultsSubject.next(new Map());
      }
      this.currentMatchId = matchId;
      this.updateMatch();
    });
  }

  public initMatch(): void {
    const matchId = this.getMatchIdFromURLParameters();
    if (matchId) {
      services.matchService.getMatchIdSubject().next(UUID.fromString(matchId));
    } else {
      this.loadLatestMatchId();
    }
  }

  public initBetfairSubscriptions(betfairService: BetfairService) {
    betfairService.matchSubscriptionsSubject.subscribe(() =>
      this.updateSubscriptionStatus()
    );
  }

  private getMatchIdFromURLParameters(): string {
    const queryString = services.history.location.search;
    const matchId = queryString
      ? new URLSearchParams(queryString).get("matchId")
      : "";

    return matchId;
  }

  private loadLatestMatchId() {
    this.httpService
      .get("/api/match-controller/latest-match")
      .then((response: any) => {
        services.matchService
          .getMatchIdSubject()
          .next(UUID.fromString(response));
      })
      .catch((reason) => {
        if (
          reason &&
          reason.message ===
            "Call to /api/match-controller/latest-match returned not found"
        ) {
          showMessage("No recent matches found", "info");
        } else {
          showMessage(`Error loading latest match: ${reason}`, "error");
        }
        this.currentMatchId = null;
        this.updateMatch();
      });
  }

  public updateMatch() {
    if (!!this.currentMatchId) {
      services.matchService
        .getOne(this.currentMatchId)
        .then((match: Match) => {
          this.currentMatch = match;
          this.currentMatchSubject.next(this.currentMatch);

          this.updateGround();
          this.updateGameState();
          this.updateMatchInfo();
          this.updateLatestBalls();
          this.updateSubscriptionStatus();
          services.matchService.updateShares(this.currentMatchId);
        })
        .catch((reason) => {
          services.history.push(getHomepageRoute());
          services.history.goForward();
          showMessage(`Could not update match: ${reason}`, "error");
        });
    } else {
      this.currentState = null;
      this.currentMatch = null;
      this.currentMatchFormat = null;
      this.currentMatchInfo = null;

      this.currentMatchSubject.next(null);
      this.currentStateSubject.next(null);
      this.currentInfoSubject.next(null);
      this.currentMatchFormatSubject.next(null);
      this.currentSubscriptionSubject.next(null);
      this.latestEventsSubject.next([]);
      services.matchService.sharedUsersSubject.next([]);
    }
  }

  public updateSubscriptionStatus() {
    if (!!this.currentMatchId) {
      this.currentSubscriptionSubject.next(
        services.betfairService.matchSubscriptions.get(
          this.currentMatchId.value
        )
      );
    } else {
      this.currentSubscriptionSubject.next(null);
    }
  }

  public async updateTeams(): Promise<void> {
    if (!!this.currentMatch) {
      return Promise.all<Team | Squad>([
        services.teamService.getOne(this.currentMatch.team1Id),
        services.teamPlayerService.getSquad(
          this.currentMatch.matchId,
          this.currentMatch.team1Id
        ),
        services.teamService.getOne(this.currentMatch.team2Id),
        services.teamPlayerService.getSquad(
          this.currentMatch.matchId,
          this.currentMatch.team2Id
        ),
      ])
        .then(([team1, squad1, team2, squad2]) => {
          this.teams = [team1 as Team, team2 as Team];
          this.squads = [squad1 as Squad, squad2 as Squad];
          this.teamsSubject.next(this.teams);
          this.squadsSubject.next(this.squads);
          services.imageService.loadSquad(squad1 as Squad);
          services.imageService.loadSquad(squad2 as Squad);
        })
        .catch((reason) => {
          showMessage(`Could not get teams: ${reason}`, "error");
        });
    } else {
      this.teams = null;
      this.squads = null;

      this.teamsSubject.next(null);
      this.squadsSubject.next(null);
    }
  }

  public updateGround() {
    if (!!this.currentMatch) {
      if (
        !this.ground ||
        this.currentMatch.groundId.value !== this.ground.groundId.value
      ) {
        services.groundService
          .getOne(this.currentMatch.groundId)
          .then((ground: Ground) => {
            this.ground = ground;
            this.groundSubject.next(ground);
          })
          .catch((reason) => {
            showMessage(`Could not get ground: ${reason}`, "error");
          });
      }
    } else {
      this.ground = null;
      this.groundSubject.next(null);
    }
  }

  public broadcastShareUpdate(matchId: string) {
    this.shareSubject.next(!!matchId && UUID.fromString(matchId));
  }

  private updateGameState() {
    this.getLatestState().then((gameState: GameState) => {
      services.simulationService.resetSimulationResultsIfBeforeTime(
        gameState.createdAt
      );
      if (
        !this.squads ||
        !this.teams ||
        this.teams[0].teamId.value !== this.currentMatch.team1Id.value ||
        this.teams[1].teamId.value !== this.currentMatch.team2Id.value ||
        !(
          !!this.currentState &&
          !!gameState &&
          this.currentState.squads[0].length === gameState.squads[0].length &&
          this.currentState.squads[1].length === gameState.squads[1].length &&
          this.currentState.squads[0].every(
            (teamPlayer, index) =>
              gameState.squads[0][index].playerId.value ===
              teamPlayer.playerId.value
          ) &&
          this.currentState.squads[1].every(
            (teamPlayer, index) =>
              gameState.squads[1][index].playerId.value ===
              teamPlayer.playerId.value
          )
        )
      ) {
        this.updateTeams();
      }
      this.broadcastGamestate(gameState);
      this.updateMatchFormat();
    });
  }

  private updateMatchInfo() {
    this.getLatestInfo().then((matchInfo: MatchInfo) => {
      this.currentMatchInfo = matchInfo;
      this.currentInfoSubject.next(this.currentMatchInfo);
    });
  }

  private updateLatestBalls() {
    this.getLatestEvents().then((latestEvents: CricketEvent[]) => {
      this.latestEvents = latestEvents;
      this.latestEventsSubject.next(this.latestEvents);
    });
  }

  public clearMatchFormat() {
    this.currentMatchFormat = null;
    this.currentMatchFormatSubject.next(this.currentMatchFormat);
  }

  private updateMatchFormat() {
    services.matchFormatService
      .getOne(this.currentState.matchFormatId)
      .then((matchFormat: MatchFormat) => {
        this.currentMatchFormat = matchFormat;
        this.currentMatchFormatSubject.next(this.currentMatchFormat);
      })
      .catch((reason) => {
        showMessage(`Could not update match format: ${reason}`, "error");
      });
  }

  private async getLatestEvents(): Promise<CricketEvent[]> {
    return await this.httpService
      .get(
        `/api/bowl-controller/get-latest-events?matchId=${this.currentMatchId.value}`
      )
      .then((response: any[]) => {
        const events: CricketEvent[] = deserializeCricketEventList(response);
        return events;
      })
      .catch((reason) => {
        showMessage(`Failed to get latest events: ${reason}`, "error");
        return [];
      });
  }

  private async getLatestInfo(): Promise<MatchInfo> {
    return await this.httpService
      .get(
        `/api/match-controller/latest-info?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => {
        const matchInfo: MatchInfo = MatchInfo.deserializeOne(response);
        return matchInfo;
      })
      .catch((reason) => {
        showMessage(`Failed to get latest match info: ${reason}`, "error");
        return null;
      });
  }

  private async getLatestState(): Promise<GameState> {
    return await this.httpService
      .get(
        `/api/match-controller/latest-state?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => {
        const gameState = GameState.deserializeOne(response.gameState);
        return gameState;
      })
      .catch((reason) => {
        showMessage(`Failed to get latest state: ${reason}`, "error");
        return null;
      });
  }

  public broadcastGamestate(gs: GameState) {
    this.currentState = gs;
    this.currentStateSubject.next(this.currentState);
  }

  public matchUpdated(gameStateResponse: any) {
    const gameState: GameState = GameState.deserializeOne(
      gameStateResponse.gameState
    );
    services.simulationService.resetSimulationResultsIfBeforeTime(
      gameState.createdAt
    );
  }

  public selectBatsman(batsmanNumber: number, playerId: UUID) {
    if (playerId === null) {
      this.removeBatsman(batsmanNumber);
    } else {
      this.httpService
        .get(
          `/api/` +
            this.controllerName +
            `/select-batsman?matchId=${this.currentMatchId.value}&playerId=${playerId.value}&batsmanNumber=${batsmanNumber}`
        )
        .then((response: any) => this.matchUpdated(response))
        .catch((reason) =>
          showMessage(`Failed to select batsman: ${reason}`, "error")
        );
    }
  }

  public selectBowler(playerId: UUID) {
    if (playerId === null) {
      this.removeBowler();
    } else {
      this.httpService
        .get(
          `/api/` +
            this.controllerName +
            `/select-bowler?matchId=${this.currentMatchId.value}&playerId=${playerId.value}`
        )
        .then((response: any) => this.matchUpdated(response))
        .catch((reason) =>
          showMessage(`Failed to select bowler: ${reason}`, "error")
        );
    }
  }

  public removeBatsman(batsmanNumber: number) {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/remove-batsman?matchId=${this.currentMatchId.value}&batsmanNumber=${batsmanNumber}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to remove batsman: ${reason}`, "error")
      );
  }

  public retireBatsman(
    batsmanNumber: number,
    percentToReturn: number,
    overToReturn: number
  ) {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/retire-batsman?matchId=${
            this.currentMatchId.value
          }&batsmanNumber=${batsmanNumber}&percentReturn=${percentToReturn}&overToReturn=${
            overToReturn - 1
          }`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to retire batsman: ${reason}`, "error")
      );
  }

  public retireBowler(
    bowlerId: UUID,
    percentToReturn: number,
    overToReturn: number
  ) {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/retire-bowler?matchId=${this.currentMatchId.value}&bowlerId=${
            bowlerId.value
          }&percentReturn=${percentToReturn}&overToReturn=${overToReturn - 1}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to retire bowler: ${reason}`, "error")
      );
  }

  public removeBowler() {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/remove-bowler?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to remove bowler: ${reason}`, "error")
      );
  }

  public removePlayer(playerId: UUID, teamId: UUID): Promise<boolean> {
    const params: Map<string, string> = new Map();
    params.set("playerId", playerId.value);
    params.set("teamId", teamId.value);
    params.set("matchId", this.currentMatchId.value);
    return this.httpService
      .get(`/api/` + this.controllerName + `/remove-player`, params)
      .then((response: any) => {
        this.matchUpdated(response);
        return true;
      })
      .catch((reason) => {
        showMessage(`Failed to remove player: ${reason}`, "error");
        return false;
      });
  }

  public movePlayer(
    playerId: UUID,
    direction: Direction,
    steps: number,
    teamId: UUID
  ): Promise<boolean> {
    const params: Map<string, string> = new Map();
    params.set("playerId", playerId.value);
    params.set("matchId", this.currentMatchId.value);
    params.set("teamId", "" + teamId.value);
    params.set("steps", "" + steps);
    params.set("direction", direction);
    return this.httpService
      .get(`/api/` + this.controllerName + `/move-player`, params)
      .then((response: any) => {
        this.matchUpdated(response);
        return true;
      })
      .catch((reason) => {
        showMessage(`Failed to move player: ${reason}`, "error");
        return false;
      });
  }

  public addPlayer(playerId: UUID, teamId: UUID): Promise<boolean> {
    const params: Map<string, string> = new Map();
    params.set("playerId", playerId.value);
    params.set("matchId", this.currentMatchId.value);
    params.set("teamId", "" + teamId.value);
    return this.httpService
      .get(`/api/` + this.controllerName + `/add-player`, params)
      .then((response: any) => {
        this.matchUpdated(response);
        return true;
      })
      .catch((reason) => {
        showMessage(`Failed to add player: ${reason}`, "error");
        return false;
      });
  }

  public substitute(
    player: Player,
    playerReplaced: Player,
    isConcussionSub: boolean,
    teamId: UUID
  ) {
    this.httpService
      .post(`/api/` + this.controllerName + `/substitute-player`, {
        matchId: this.currentMatchId.value,
        teamId: teamId.value,
        playerReplacing: player.playerId.value,
        concussionSub: isConcussionSub,
        playerReplaced: !!playerReplaced ? playerReplaced.playerId.value : null,
      })
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to add substitute: ${reason}`, "error")
      );
  }

  public timeOut(player: Player) {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/timed-out?matchId=${this.currentMatchId.value}&batsmanId=${player.playerId.value}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to submit time-out: ${reason}`, "error")
      );
  }

  public addPenalty(runs: number, innings: number) {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/penalty?matchId=${this.currentMatchId.value}&runs=${runs}&innings=${innings}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to submit penalty: ${reason}`, "error")
      );
  }

  public changeFormat(
    newMatchFormatId: UUID,
    duckworthTarget: number,
    duckworthTargetInnings: number
  ) {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/format-change?` +
          `matchId=${this.currentMatchId.value}&newMatchFormat=${newMatchFormatId.value}` +
          `${
            !!duckworthTarget
              ? `&duckworthTarget=${
                  duckworthTarget - 1
                }&duckworthTargetInnings=${duckworthTargetInnings}`
              : ""
          }`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to change format: ${reason}`, "error")
      );
  }

  public switchBatsmen() {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/switch-batsmen?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to switch batsmen: ${reason}`, "error")
      );
  }

  public declare() {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/declare?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to submit declaration: ${reason}`, "error")
      );
  }

  public surge() {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/surge-taken?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to submit surge: ${reason}`, "error")
      );
  }

  public undo() {
    this.httpService
      .get(
        `/api/` +
          this.controllerName +
          `/undo-latest-state?matchId=${this.currentMatchId.value}`
      )
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) => showMessage(`Failed to undo: ${reason}`, "error"));
  }

  public makeDecision(decisionType: DecisionType, decisionData: any) {
    this.httpService
      .post(`/api/` + this.controllerName + `/make-decision`, {
        matchId: this.currentMatchId.value,
        decisionType,
        decisionData,
      })
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) =>
        showMessage(`Failed to submit decision: ${reason}`, "error")
      );
  }

  public async addTossInfo(
    tossWinner: Team,
    decision: TossChoice
  ): Promise<any> {
    const params: Map<string, string> = new Map();
    params.set("matchId", this.currentMatchId.value);
    params.set("tossWinner", tossWinner.teamId.value);
    params.set("decision", decision);
    return await this.httpService
      .get("/api/" + this.controllerName + "/toss", params)
      .then((response: any) => this.matchUpdated(response))
      .catch((reason) => showMessage(`Failed to add toss: ${reason}`, "error"));
  }
}
