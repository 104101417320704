import { Icon } from "@mui/material";
import { useState } from "react";
import {
  PowerplayAdjustmentField,
  PowerplayAdjustments,
} from "../../types/preferences/admin-preferences";
import { powerplayPercentsExpansionButtonStyle } from "../admin-settings-page/match-specific-settings-component";
import NumberSelector from "../entity-management/entity-selectors/number-selector";
import {
  PercentDistributionValue,
  PercentsDistributionBiasAdjuster,
} from "../stats-editing-components/percent-distribution-bias-adjuster";
import PowerplayAdjustmentsGraph from "./powerplay-adjustments-graph";

interface Props {
  powerplayAdjustments: PowerplayAdjustments;
  ballsInPowerplay: number;
  onUpdate: (updatedAdjustments: PowerplayAdjustments) => void;
}

interface PowerplayConfig {
  property: PowerplayAdjustmentField;
  name: string;
  min: number;
  max: number;
  dp: number;
  step: number;
}

export default function PowerplaySettingsComponent({
  powerplayAdjustments,
  ballsInPowerplay,
  onUpdate,
}: Readonly<Props>): React.JSX.Element {
  const [powerplayPercentsExpanded, setPowerplayPercentsExpanded] =
    useState<boolean>(false);

  function updatePowerplayAdjustment(
    powerplayAdjustmentsField: PowerplayAdjustmentField,
    value: number
  ) {
    powerplayAdjustments[powerplayAdjustmentsField] = value;
    onUpdate(powerplayAdjustments);
  }

  function updateBias(property: PercentDistributionValue, value: number) {
    powerplayAdjustments.powerplayPercentBiases[property] = value;
    onUpdate(powerplayAdjustments);
  }

  const baseConfig: PowerplayConfig[] = [
    {
      property: "basePushAdjustment",
      name: "Base Push Adjustment",
      min: -100,
      max: 100,
      dp: 0,
      step: 1,
    },
    {
      property: "baseWicketBias",
      name: "Base Wicket Bias",
      min: 0,
      max: 10,
      dp: 2,
      step: 0.01,
    },
    {
      property: "baseSrBias",
      name: "Base Strike Rate Bias",
      min: 0,
      max: 10,
      dp: 2,
      step: 0.01,
    },
  ];

  const swingConfig: PowerplayConfig[] = [
    {
      property: "swingUpToBall",
      name: "Swing up to ball",
      min: 0,
      max: 1000,
      dp: 0,
      step: 1,
    },
    {
      property: "swingPower",
      name: "Swing Power",
      min: 0,
      max: 5,
      dp: 2,
      step: 0.01,
    },
    {
      property: "srMultiplier",
      name: "Swing Strike Rate Penalty",
      min: 0,
      max: 5,
      dp: 1,
      step: 0.1,
    },
    {
      property: "wicketMultiplier",
      name: "Swing Wicket Penalty",
      min: 0,
      max: 5,
      dp: 1,
      step: 0.1,
    },
    {
      property: "swingExponent",
      name: "Curve Steepness",
      min: 0,
      max: 10,
      dp: 1,
      step: 0.1,
    },
  ];

  return (
    <>
      <div className="match-stats-powerplay-graph-container">
        <PowerplayAdjustmentsGraph
          adjustments={powerplayAdjustments}
          ballsInPowerplay={ballsInPowerplay}
        />
      </div>

      <div className="match-stats-powerplay-columns">
        <div className="match-stats-powerplay-settings-column">
          {baseConfig.map((adjustment) => (
            <NumberSelector
              key={adjustment.property}
              label={adjustment.name}
              min={adjustment.min}
              max={adjustment.max}
              step={adjustment.step}
              decimalPlaces={adjustment.dp}
              initial={powerplayAdjustments[adjustment.property]}
              onValid={(valid: number) =>
                updatePowerplayAdjustment(adjustment.property, valid)
              }
            />
          ))}
        </div>

        <div className="match-stats-powerplay-settings-column">
          {swingConfig.map((adjustment) => (
            <NumberSelector
              key={adjustment.property}
              label={adjustment.name}
              min={adjustment.min}
              max={adjustment.max}
              step={adjustment.step}
              decimalPlaces={adjustment.dp}
              initial={powerplayAdjustments[adjustment.property]}
              onValid={(valid: number) =>
                updatePowerplayAdjustment(adjustment.property, valid)
              }
            />
          ))}
        </div>
      </div>

      <button
        style={powerplayPercentsExpansionButtonStyle}
        onClick={() => setPowerplayPercentsExpanded(!powerplayPercentsExpanded)}
      >
        <div>Powerplay Percent Bias</div>
        <Icon>{powerplayPercentsExpanded ? "expand_less" : "expand_more"}</Icon>
      </button>
      {powerplayPercentsExpanded && (
        <PercentsDistributionBiasAdjuster
          biases={powerplayAdjustments.powerplayPercentBiases}
          updateValue={(property: PercentDistributionValue, value: number) =>
            updateBias(property, value)
          }
        />
      )}
    </>
  );
}
