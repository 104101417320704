import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { GameState } from "../../../types/entities/game-state";
import { MatchFormat } from "../../../types/entities/match-format";
import { Squad } from "../../../types/entities/squad";
import { services } from "../../../types/services";
import { SimulationResult } from "../../../types/simulator/simulation-result";
import { MatchStatsWrapper } from "../../../types/stats/match-stats";
import { PlayerStatsWrapper } from "../../../types/stats/player-stats";
import NumberSelector from "../../entity-management/entity-selectors/number-selector";
import {
  TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_DP,
  TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MAX,
  TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MIN,
  TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_STEP,
} from "../../entity-management/entity-selectors/player-selector-global-biases-component";
import { format } from "../../simulator-page/simulator-utils";
import OversReservationButtons from "../../stats-editing-components/overs-reservation-buttons";

import { getPlayers } from "./scorecard-utils";

type Props = {
  playerStats: Map<string, PlayerStatsWrapper>;
  gameState: GameState;
  simulationResult: SimulationResult;
  matchStats: MatchStatsWrapper;
  matchFormat: MatchFormat;
  squad1: Squad;
  squad2: Squad;
  onSpinnerEdit: (editing: boolean) => void;
};

type updateMatchStatsProperty = "bowlerMatchPushAdjustments";

export default function DesiredBallsDisplay({
  playerStats,
  gameState,
  simulationResult,
  matchStats,
  matchFormat,
  squad1,
  squad2,
  onSpinnerEdit,
}: Readonly<Props>): React.JSX.Element {
  const updateMatchStats = (
    property: updateMatchStatsProperty,
    teamId: string,
    playerId: string,
    value: number
  ) => {
    const newMatchStats = MatchStatsWrapper.clone(matchStats);
    newMatchStats.matchStats[property]?.get(teamId).set(playerId, value);
    services.matchStatsService.updateMatchStats(newMatchStats);
  };

  const updateDesiredBalls = (
    playerStats: PlayerStatsWrapper,
    newValue: number
  ) => {
    services.playerStatsService.updatePlayerStats({
      ...playerStats,
      playerStats: {
        ...playerStats.playerStats,
        desiredBalls: newValue,
      },
    });
  };

  const { players, totalBallsBowledAvg } = getPlayers(
    squad1,
    squad2,
    playerStats,
    gameState,
    simulationResult,
    matchFormat
  );

  return (
    <div
      className={"scorecards-container full-width-scorecard"}
      style={{ gridColumn: "1/-1" }}
    >
      <TableContainer sx={{ overflowX: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Death Reservations</TableCell>
              <TableCell align="right">Desired Balls</TableCell>
              <TableCell align="right">
                Simulated Balls
                {totalBallsBowledAvg < 0
                  ? ""
                  : " (" + format(totalBallsBowledAvg) + ")"}
              </TableCell>
              <TableCell align="right">Push Adjustment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {players?.map(
              (
                {
                  player,
                  playerStatsWrapper,
                  ballsBowledAvg,
                  teamNumber,
                  playerTeamId,
                },
                index
              ) => (
                <TableRow
                  key={player.playerId.value}
                  sx={{
                    backgroundColor: index % 2 === 0 ? "#eeeeee" : "#f8f8ff",
                  }}
                >
                  <TableCell sx={{ padding: "5px" }}>
                    {player.longName}
                  </TableCell>
                  <TableCell sx={{ padding: "5px" }}>
                    <OversReservationButtons
                      playerId={player.playerId}
                      gameState={gameState}
                      matchFormat={matchFormat}
                      matchStats={matchStats}
                      teamNumber={teamNumber}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "5px" }}>
                    <NumberSelector
                      min={0}
                      max={1000000}
                      step={1}
                      decimalPlaces={0}
                      initial={playerStatsWrapper?.playerStats.desiredBalls}
                      onValid={(newValue) =>
                        updateDesiredBalls(playerStatsWrapper, newValue)
                      }
                      onManualEdit={() => onSpinnerEdit(true)}
                      onManualEditFinished={() => onSpinnerEdit(false)}
                    />
                  </TableCell>
                  <TableCell sx={{ padding: "5px", textAlign: "right" }}>
                    {ballsBowledAvg === -1 ? "" : format(ballsBowledAvg)}
                  </TableCell>
                  <TableCell sx={{ padding: "5px" }}>
                    <NumberSelector
                      min={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MIN}
                      max={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_MAX}
                      step={TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_STEP}
                      decimalPlaces={
                        TEMPORARY_PUSH_ADJUSTMENT_NUMBER_SELECTOR_DP
                      }
                      initial={
                        matchStats?.matchStats.bowlerMatchPushAdjustments
                          .get(playerTeamId)
                          ?.get(player.playerId.value) || 0
                      }
                      onValid={(value: number) => {
                        updateMatchStats(
                          "bowlerMatchPushAdjustments",
                          playerTeamId,
                          player.playerId.value,
                          value
                        );
                      }}
                      onManualEdit={() => onSpinnerEdit(true)}
                      onManualEditFinished={() => onSpinnerEdit(false)}
                    />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
