import { CircularProgress } from "@mui/material";

import { SimulatorRoute } from "../../types/route-helpers";
import { SimulationResult } from "../../types/simulator/simulation-result";

interface Props {
  simulationResult: SimulationResult;
  simulationLoading: boolean;
}

export default function PlayerTableRowSimulationLoadingData({
  simulationResult,
  simulationLoading,
}: Readonly<Props>): React.JSX.Element {
  return (
    <SimulatorRoute>
      {!simulationResult && !simulationLoading && (
        <div className="italic">No Simulation Data</div>
      )}
      {simulationLoading && (
        <div className="stats-loading">
          Loading...
          <CircularProgress size={"2em"} />
        </div>
      )}
    </SimulatorRoute>
  );
}
